.search-wrapper {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.search-wrapper input {
  width: 100%;
  font-size: 13px;
  line-height: 15px;
  border-radius: 20px;
  border: solid 1px #ebebeb;
  padding: 13px 10px 13px 55px;
  background: #fff url(../../assets/images/search_icon.png) no-repeat 2%
    center;
  outline: 0;
  font-weight: 500;
}
