.release-lib-popup-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9;
}
.release-lib-popup{
    max-width: 570px;
    width: 100%;
    background: #fff;
    z-index: 11;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 7px;
    padding: 16px;
}

.release-lib-popup-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 7px;
    border-bottom: solid 1px #EBEBED;
}

.release-lib-popup-header h3{
    font-size: 20px;
    font-weight: 600;
}

.release-lib-popup-header .close-pop{
    background: url(../../assets/images/close-icon-darkgrey.png) no-repeat center center;
    background-size: 100%;
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.release-lib-popup-body{
    padding: 20px 0;
}

.release-lib-popup-body .input-search {
    position: relative;
    margin-top: 8px;
  }
  
  .release-lib-popup-body  input[type="text"], .release-lib-popup-body  input[type="search"] {
    padding: 2px 5px 2px 5px ;
    height: 36px;
    font-size: 12px;
    width: 100%;
    border: solid 1px #00000033;
    border-radius: 4px;
  }

  .release-lib-popup-body .release-lib-subscribe-email input[type="text"], .release-lib-popup-body .release-lib-subscribe-email input[type="email"]{
    font-size: 10px;
    font-weight: 300;
    height: 32px;
  }

  .release-lib-popup-body .release-lib-subscribe-email input[type="text"]::placeholder{
    color: #535353;
  }

  .release-lib-popup-body .input-search  input[type="text"],  .release-lib-popup-body .input-search  input[type="search"] {
    padding-left: 24px;
  }
  
  .release-lib-popup-body .input-search::after{
    position: absolute;
    content:'';
    background: url(../../assets/images/search_icon.png) no-repeat 0 0;
    background-size: 100%;
    width: 14px;
    height:14px;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
  }

  .release-lib-popup-body .subscription-tag-wrapper{
    /* max-height: 240px;
    overflow: auto; */
  }

  .release-lib-popup-body .subscription-tag-wrapper-main{
    margin: 10px 0 25px;
  }

  .release-lib-popup-body .subscribe-to-all{
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .release-lib-popup-body  .relese-lib-tag-item{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 0;
    border-bottom: solid 1px #F8F8F8;
  }

  .release-lib-popup-body  .relese-lib-taglist{
    padding-top: 10px;
  }

  .release-lib-popup-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 7px;
    border-top: solid 1px #EBEBED;
}

.release-lib-popup-footer .btn-lib-common{
    padding: 6px 10px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    border: solid 1px transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Roboto';
    cursor: pointer;
}

.release-lib-popup-footer .btn-lib-common.btn-dark{
    color: #fff;
    background: #33455F;
}

.release-lib-popup-footer .btn-lib-common.btn-light{
    color: #33455F;
}


.release-lib-popup  .container-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 14px;
}

.release-lib-popup  .check-text{
display: inline-block;
padding-left: 15px;
}

/* Hide the browser's default checkbox */
.release-lib-popup  .container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.release-lib-popup  .container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #00000033;
}


/* When the checkbox is checked, add a blue background */
.release-lib-popup  .container-checkbox input:checked ~ .checkmark {
  background-color: #33455F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.release-lib-popup  .container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.release-lib-popup  .container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.release-lib-popup  .container-checkbox .checkmark:after {
left: 5px;
top: 0px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.release-lib-popup-body  .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.release-lib-popup-body  .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.release-lib-popup-body  .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transition: .4s;
  transition: .4s;
}

.release-lib-popup-body  .switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.release-lib-popup-body  .switch input:checked+.slider {
  background-color: #33455F;
}

.release-lib-popup-body  .switch input:focus+.slider {
  box-shadow: 0 0 1px #33455F;
}

.release-lib-popup-body  .switch input:checked+.slider:before {
  -webkit-transition: .4s;
  transition: .4s;
}

.release-lib-popup-body  .switch input:checked+.slider {
  background-color: #33455F;
}

.release-lib-popup-body  .switch input:focus+.slider {
  box-shadow: 0 0 1px #33455F;
}

.release-lib-popup-body  .switch input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(2px);
}

.release-lib-popup-body  .switch .slider.round {
  border-radius: 34px;
}

.release-lib-popup-body  .switch .slider.round:before {
  border-radius: 50%;
}


.authenticate-otp-form-flex{
  display: flex;
  gap: 5px;
  padding-top: 6px;
}

.authenticate-otp-form-flex input[type = "number"], .authenticate-otp-form-flex input[type = "text"]{
  width: 32px;
  height: 32px;
  font-size: 12px;
  border: solid 1px #BABABA;
  border-radius: 4px;
  font-family: 'Roboto';
  padding: 1px 10px;
}

.release-lib-popup.authenticate-popup {
  max-width: 450px;
}


/* Chrome, Safari, Edge, Opera */
.authenticate-otp-form-flex  input::-webkit-outer-spin-button,
.authenticate-otp-form-flex  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.authenticate-otp-form-flex  input[type=number], .authenticate-otp-form-flex  input[type=text] {
  -moz-appearance: textfield;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px 3px 10px;
}

.authenticate-otp-form-wrapper {
  font-size: 12px;
  font-family: 'Roboto';
}

.release-lib-error{
  padding-top: 5px;
  display: inline-block;
  color: red;
}

.release-lib-popup .subscription-tag-wrapper-main .release-lib-error{
  padding-top: 10px;
}

.release-lib-popup-footer button.disabled{
  opacity: 0.65;
  pointer-events: none;
}