.release-page-center {
  max-width: 1440px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: auto;
}

.release-page-main-wrapper header{
    background: #33455F;
    padding: 6px 12px;
    color: #fff;
}

.release-page-header-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px; 
}

.release-page-main-wrapper header .logo-wrapper{
  display: flex;
  gap: 10px;
  align-items: center;
}

.release-page-main-wrapper header .logo-wrapper img{
  /* max-height: 35px; */
  width: 90px;
  height: 90px;
}

.release-page-main-wrapper header .logo-wrapper .company-name{
  font-size: 17px;
}

.header-logo-image{
  cursor: pointer;
}
.release-page-main-wrapper .btn-common{
  height: 32px;
  font-weight: 14px;
  font-weight: 500;
  padding: 4px 10px  4px  10px;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Roboto';
}

.release-page-main-wrapper .btn-common.btn-white{
  background-color: #fff;
  color: #151F2D;
  cursor: pointer;
}

.release-page-main-wrapper .btn-common.btn-with-img{
  display: flex;
  align-items: center;
  gap: 4px;
}

.release-page-main-wrapper .release-title-wrapper{
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.release-page-main-wrapper .release-title-wrapper  .release-title-left{
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.release-page-main-wrapper .release-title-wrapper  .release-title-left h1{
  font-size: 24px;
  font-weight: bold;
}

.release-page-main-wrapper .release-title-wrapper  .release-title-left h2{
  font-size: 20px;
  padding-top: 3px;
}

.release-page-main-wrapper .release-title-wrapper .powered-by{
  font-size: 10px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.release-page-main-wrapper .release-title-wrapper .powered-by .logo-icon{
  color: #000;
  align-items: flex-start;
  gap: 4px;
}

.release-page-main-wrapper .release-title-wrapper .powered-by .logo-icon h1,.release-page-main-wrapper .release-title-wrapper .powered-by .logo-icon small{
  cursor: context-menu;
}
.release-page-main-wrapper .tag-bg{
  display: inline-flex;
  padding: 4px 7px;
  border-radius: 2px;
  align-items: center;
  flex-grow: 1;
  max-width: 150px;
  word-break: break-word;
}

.release-page-main-wrapper .tags-popup .tag-name {
  padding: 1px 10px 1px 8px;
}

.release-page-main-wrapper .release-title-wrapper .powered-by  .logo-icon p{
  padding-top: 1px;
}

.release-page-main-wrapper  .release-note-main-wrapper{
  display: flex;
  gap: 16px;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar{
  max-width: 190px;
  width: 100%;
  border: solid 1px #BCBCBC;
  background: #F5F5F580;
  padding: 20px 16px;
  border-radius: 4px;
  color: #7C7C7C;
  flex-shrink: 0;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year:not(:last-child){
  padding-bottom: 24px;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year h3{
  color:#000 ;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 12px;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year h3 span,
.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year ul li span{
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
  padding-left: 3px;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year ul{
  padding: 0;
  margin: 0;
  list-style: none;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year ul li{
  margin: 0 0px 8px 0px;
  padding-left: 14px;
  font-size: 12px;
  font-weight: 900;
  position: relative;
  transition: all ease .3s;
  cursor: pointer;
  display: inline-block;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year ul li.active{
  color: #2B6FDC;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year ul li::before{
  position: absolute;
  content: '';
  width: 6px;
  height: 6px;
  background: #D9D9D9;
  top: 3px;
  left: 0;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-version-sidebar .release-version-per-year ul li.active::before{
  background: #2B6FDC;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-note-right-main{
  display: flex;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-note-right-main,.release-page-main-wrapper  .release-note-main-wrapper .release-note-right-container-wrapper{
  flex-grow: 1;
}

.release-page-main-wrapper  .release-note-main-wrapper .release-note-right-main .release-note-right-sidebar{
  max-width: 220px;
  width: 100%;
  flex-shrink: 0;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper{
  background: #FAFAFA80;
  border: solid 1px #BCBCBC;
  padding: 12px;
  border-radius: 5px;
  font-size: 12px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .filter-area{
  padding-top: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-wrapper {
  position: relative;
  max-width: 210px;
  width: 100%;

}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-selected {
  width: 100%;
  min-height: 34px;
  height: auto;
  border: solid 1px #bcbcbc;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: #fff;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-selected-items {
  gap: 6px;
  padding: 7px 8px;
  align-items: center;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-selected-items {
  flex-grow: 1;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-arrow {
  width: 24px;
  height: 24px;
  border-left: solid 1px #CCCCCC;
  position: relative;
  cursor: pointer;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-arrow::after{
  position: absolute;
  content: '';
  background-size: 100%;
  background: url(./../../assets/images/down_grey_sm.png) no-repeat 0 0;
  width: 15px;
  height: 8px;
  top:8px;
  left: 3px;
  /* transform: translate(-50%,-50%); */
  transition: all ease .3s;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-wrapper.open .multiselect-dropdown-arrow::after {
  transform: rotate(180deg);
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-list {
  position: absolute;
  top: 34px;
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-top: none;
  width: 100%;
  padding: 8px 12px;
  border-radius: 0 0 3px 3px;
  display: none;
  z-index: 2;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-list .no-results{
  padding: 7px 0;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-wrapper.open  .multiselect-dropdown-list {
  display: block;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-search {
  position: relative;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-search input[type="text"], .release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-search input[type="search"]  {
  padding: 2px 5px 2px 24px ;
  height: 28px;
  font-size: 12px;
  width: 100%;
  border: solid 1px #00000033;
  border-radius: 4px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-dropdown-search::after{
  position: absolute;
  content:'';
  background: url(./../../assets/images/search_icon.png) no-repeat 0 0;
  background-size: 100%;
  width: 14px;
  height:14px;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-taglist-item{
 display: flex;
 gap: 10px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-taglist {
  margin-top: 10px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-taglist-item:last-child {
  border-bottom: none;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-taglist-item {
  padding: 7px 0;
  border-bottom: solid 1px #F8F8F8;
}


.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .multiselect-more-items{
  font-size: 12px;
  color: #797979;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .multiselect-more-items::after{
  position: absolute;
  content: '';
  background: #797979;
  height: 1px;
  top: calc(100% + 1px);
  width: 100%;
  left: 0;
}



.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .multiselect-close{
  background: url(./../../assets/images/close-icon-grey.png) no-repeat center center;
  background-size: 100%;
  width: 12px;
  height: 12px;
  cursor: pointer;
  flex-shrink: 0;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .filter-tag-list{
  display: flex;
  gap: 6px;
  align-items: center;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .filter-tag-list > div.clear-filter{
  flex-shrink: 0;
  cursor: pointer;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .filter-tag-list > div.clear-filter,.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .filter-tag-list div.filter-selected-item{
  background: #EDEDED;
  border-radius: 2px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  /* flex-shrink: 0; */
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .filter-tag-list > div.filter-selected-item-wrapper{
  display: flex;
  align-items: center;
  gap: 5px;
  /* max-width: 570px; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
  padding: 0px;
  background: transparent;
  max-width: 83%;
  word-break: break-word;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper  .filter-tag-list .tag-close{
  background: url(./../../assets/images/close-icon-grey.png) no-repeat 0 0;
  width: 11px;
  height: 10px;
  display: inline-block;
  background-size: 100%;
  cursor: pointer;
}

.release-page-main-wrapper  .container-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 14px;
}

.release-page-main-wrapper  .check-text{
display: inline-block;
padding-left: 15px;
}

/* Hide the browser's default checkbox */
.release-page-main-wrapper  .container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.release-page-main-wrapper  .container-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #00000033;
}


/* When the checkbox is checked, add a blue background */
.release-page-main-wrapper  .container-checkbox input:checked ~ .checkmark {
  background-color: #33455F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.release-page-main-wrapper  .container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.release-page-main-wrapper  .container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.release-page-main-wrapper  .container-checkbox .checkmark:after {
left: 5px;
top: 0px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .input-search {
  position: relative;
  margin-top: 8px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .input-search input[type="text"], .release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .input-search input[type="search"] {
  padding: 7px 5px 7px 24px ;
  height: 32px;
  font-size: 12px;
  width: 100%;
  border: solid 1px #00000033;
  border-radius: 4px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .filter-wrapper .input-search::after{
  position: absolute;
  content:'';
  background: url(./../../assets/images/search_icon.png) no-repeat 0 0;
  background-size: 100%;
  width: 14px;
  height:14px;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
}


.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper{
  margin-top: 18px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item{
  margin-bottom: 24px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .title{
  position: relative;
  color: #2B6FDC;
  font-size: 16px;
  font-weight: bold;
  padding-left: 25px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .title::before{
  position: absolute;
  content: '';
  background: url(./../../assets/images/library.png) no-repeat 0 0;
  background-size: 100%;
  left: 0;
  top: 0;
  width: 18px;
  height: 19px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-outer{
  padding: 16px 0 0 24px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail{
  background: #2B6FDC12;
  border: solid 1px #2B6FDC80;
  padding: 12px 16px;
  border-radius: 6px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-header{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
  border-bottom: solid 1px #BCBCBC4A;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-header h3{
  font-size: 14px;
  font-weight: bold;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-header .release-library-tag-list-wrapper{
  display: flex;
  align-items: center;
  gap: 4px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-header .release-library-tag-item{
  padding: 5px 4px;
  border-radius: 2px;
  font-size: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-header .release-library-tag-item .tag-color{
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-description{
  padding-top: 12px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-description .release-library-item-detail-author{
  font-size: 12px;
  font-weight: 500;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-description p{
  font-size: 12px;
  padding-right: 13px;
  line-height: 16px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-description .release-lib-text-wrapper{
  margin: 12px 0 12px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-description p span{
  color: #1C4384;
  text-decoration: underline;
  cursor: pointer;
  padding: 1px 4px 6px 5px;
  display: inline-block;
  position: relative;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-description p span.see-more.is-more{
  padding-left: 15px;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-detail-description p span.see-more.is-more::before{
  position: absolute;
  content: '...';
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
}



.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-footer{
  border-top: solid 1px #E6E6E6;
  padding-top: 7px;
  color: #1C4384;
  text-align: right;
}

.release-page-main-wrapper  .release-note-right-main .release-note-right-container-wrapper .release-library-detail-wrapper .release-library-item-footer span{
  cursor: pointer;
}

/* @media screen and (max-width:2000px) {
  .release-page-main-wrapper .release-note-right-main .release-note-right-container-wrapper .filter-tag-list > div.filter-selected-item-wrapper{
    max-width: 28.5vw;
  }
} */


.release-library-detail-wrapper .no-results{
  padding: 100px 0 0;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}


.release-library-detail-wrapper table tr td,  .release-library-detail-wrapper table tr th {
  border: 1px solid var(--tab-border);
  padding: 10px 8px;
}

.release-library-detail-wrapper table tr th {
  text-align: left;
  background: #f9f9f9;
}

.release-library-detail-wrapper table tr:hover {
  background-color: transparent;
}

.release-library-detail-wrapper table {
  width: calc(100% - 20px);
  border-collapse: collapse;
  margin-top: 10px;
  padding-right: 5px;
}


.release-library-detail-wrapper ul{
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}


  .minimize-height{
  height: 100px;
  overflow: hidden;
}


.see-text {
  /* 2B6FDC */
color: #1C4384;
text-decoration: underline;
cursor: pointer;
display: inline-block;
padding: 5px;
margin-bottom: 10px;
}

.see-text-wrapper {
  text-align: right;
  padding-right: 8px;
}
