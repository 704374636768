.feedback-outer-wrapper {
    display: flex;
    position: relative;
    min-height: calc(100vh - 100px);
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
}

.feedback-outer-wrapper .tag-cloud-listing {
    max-height: calc(100vh - 170px);
    margin-bottom: 20px;
}

.feedback-outer-wrapper .tag-cloud-listing-wrapper {
    height: calc(100vh - 170px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.feedback-outer-wrapper  .middle-container-bottom,.feedback-outer-wrapper .library-left-container{
    margin-top: 100px;
}

.feedback-outer-wrapper .library-left-container{
    position: fixed;
    left: 0;
    top: 0;
}

.feedback-outer-wrapper  .right-main-container {
    margin-left: 250px;
}

.feedback-smileys-wrapper{
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
    /* border: solid 1px #d9dae0; */
    border-radius: 30px;
    margin: 0 20px;
}

.feedback-headings {
    align-items: center;
    justify-content: space-between;
}

.feedback-textarea-wrapper textarea{
    min-height: 250px;
    padding: 10px;
}

.feedback-btns-wrapper{
    justify-content: flex-end;
}

/* .blue-button {
    background: #2b6fdb;
    border: solid 1px #2b6fdb;
    padding: 8px 14px 8px 32px;
    color: var(--primary-white);
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    text-align: left;
    outline: none;
    font-size: 12px;
    font-family: 'Roboto';
} */

.feedback-btns-wrapper .blue-button,.feedback-bottom-wrapper  .blue-button{
    background: #33455f;
    border: 1px solid #33455f;
    border-radius: 6px;
    color: var(--primary-white);
    cursor: pointer;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    padding: 8px 14px;
    position: relative;
    text-align: left;
}

.white-button {
    background: transparent;
    border: solid 1px #e2e4e9;
    padding: 8px 14px 8px 14px;
    color: var(--primary-black);
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    text-align: left;
    outline: none;
    font-size: 14px;
    font-family: 'Roboto';
}

.white-button.back::after {
    background: url(../../assets/images/back.png) no-repeat 0 0;
    background-size: 100%;
}

.blue-button.send::after {
    /* background: url(../../assets/images/send.png) no-repeat 0 0; */
    filter: invert(1);
    background-size: 100%;
}

/* .blue-button.unsubscribe::after {
    background: url(../../assets/images/unsubscribe-icon.png) no-repeat 0 0;
    background-size: 100%;
    filter: invert(1);
} */

/* .red-button.delete::after {
    background: url(../../assets/images/delete.png) no-repeat 0 0;
    filter: invert(1);
    background-size: 100%;
}

.white-button.notification span::after {
    background: url(../../assets/images/notification-icon.png) no-repeat 0 0;
    background-size: 100%;
    width: 13px;
    height: 16px;
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
}

.white-button.back::after {
    background: url(../../assets/images/back.png) no-repeat 0 0;
    background-size: 100%;
    width: 16px;
    height: 16px;
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
}

.blue-button:after,.red-button:after {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
} */

.blue-button:not(:last-child) {
    /* margin-right: 15px; */
}

.red-button {
    background: #e2375e;
    border: solid 1px #e2375e;
    padding: 8px 14px 8px 14px;
    color: #fff;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    text-align: left;
    outline: none;
    font-size: 12px;
    font-family: 'Roboto';
    margin-right: 15px;
}

.feedback-smiley-item {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.feedback-smiley-item.sad {
    background: url(../../assets/images/sad_smiley_grey.png) no-repeat 0 0;
    background-size: 100%;
}

.feedback-smiley-item.sad.active {
    background: url(../../assets/images/sad_smiley.png) no-repeat 0 0;
    background-size: 100%;
}

.feedback-smiley-item.nutrel {
    background: url(../../assets/images/nutrel_smiley_grey.png) no-repeat 0 0;
    background-size: 100%;
}

.feedback-smiley-item.nutrel.active {
    background: url(../../assets/images/nutrel_smiley.png) no-repeat 0 0;
    background-size: 100%;
}

.feedback-smiley-item.happy {
    background: url(../../assets/images/happy_smiley_grey.png) no-repeat 0 0;
    background-size: 100%;
}

.feedback-smiley-item.happy.active {
    background: url(../../assets/images/happy_smiley.png) no-repeat 0 0;
    background-size: 100%;
}

.feedback-release-content-wrapper{
    margin-top: 15px;
    border-bottom: solid 1px #d9dae0;
    padding: 15px;
    background-color: #fff;
}

.feedback-text-wrapper{
    border: solid 1px #d9dae0;
    padding: 15px;
    background-color: #fff;
    margin-top: 25px;
}

.feedback-textarea-wrapper.form-group,.feedback-text-bottom-wrapper .form-group{
    padding: 0;
}

.feedback-right-btn-wrapper{
    flex-direction: column;
}

.feedback-text-bottom-wrapper{
    margin-top: 20px;
}

.feedback-right-btn-wrapper button{
    margin-bottom: 25px;
    text-align: center;
}

.feedback-right-btn-wrapper .blue-button:not(:last-child) {
    margin-right: 0;
}

.feedback-release-content-wrapper p{
    padding-bottom: 10px;
}

.feedback-outer-wrapper .release-library-middle-container {
    background: #fff;
}

.feedback-text-bottom-wrapper,.feedback-bottom-wrapper{
    justify-content: space-between;
    align-items: center;
}

.feedback-text-bottom-wrapper .form-group input{
    height: unset;
    font-size: 12px;
}

.feedback-text-bottom-wrapper .form-group{
    max-width: 400px;
    width: 100%;
}

.feedback-bottom-wrapper{
    padding: 25px 0 0;
}

.feedback-bottom-left div:not(:last-child){
    margin-right: 30px;
}

.feedback-bottom-left label{
    color: #000;
    font-weight: 600;
    padding-bottom: 8px;
    display: inline-block;
}

.feedback-bottom-left{
    padding-right: 35px;
}

button.add-new {
    width: 16px;
    height: 16px;
    background: url(../../assets/images/plus_icon_grey.png) no-repeat 0 0;
    background-size: 100%;
    outline: none;
    border: none;
    cursor: pointer;
}

.tag-cloud-listing-wrapper button.notification{
    text-align: center;
}

.tag-cloud-listing-wrapper button.notification span{
    position: relative;
}

.release-library-header {
    position: fixed;
    width: 100%;
    /* max-width: 756px; */
    /* margin-bottom: 20px; */
    z-index:6;
    left: 0;
    top: 0;
}

.feedback-smileys-wrapper div img{
    /* filter: grayscale(100%); */
    /* filter: brightness(0) saturate(100%) invert(88%) sepia(0%) saturate(333%) hue-rotate(209deg) brightness(91%) contrast(101%); */
}

.feedback-smileys-wrapper div.active img{
    /* filter:unset; */
}

.feedback-submit-main .feedback-smileys-wrapper div {
    cursor: pointer;
    padding: 3px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedback-submit-main .feedback-smileys-wrapper div.active {
    border: solid 1px #000;
    overflow: hidden;
    border-radius: 50%;
}

/* .subscribe::after {
    background: url(../../assets/images/subscribe_icon.png) no-repeat 0 0;
    background-size: 100%;
    width: 16px;
    height: 16px;
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    filter: invert(1);
} */

.add-tags-list-wrapper.tags-list-wrapper{
    max-height: 240px;
}

.add-tags-popup .blue-button.cancel {
    padding: 8px 14px 8px 14px;
}

.popup-min.add-tags-popup.taglist-popup {
    max-width: 490px;
}
  .toggle-switch-button-wrapper{
    font-size: 13px;
    font-weight: bold;
    padding: 0px 8px;
    margin-top: 20px;
    gap:20px;
  }
 
  .toggle-switch-item{
    gap: 10px;
  }

  .toggle-switch-item label{
    flex-shrink: 0;
  }

  .toggle-switch-item:not(:last-child){
    margin-bottom: 10px;
  }
