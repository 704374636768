.library-outer-wrapper {
  display: flex;
  position: relative;
  min-height: calc(100vh - 100px);
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

/* release library table css starts */

/* .library-outer-wrapper table td .tags-flex {
  justify-content: center;
} */

.library-outer-wrapper .dashboard-tables-wrapper table tr td {
  font-size: 12px;
}

.table-data-pagination {
  margin-top: 15px;
  align-items: center;
  justify-content: center;
}

.table-data-pagination .first,
.table-data-pagination .last {
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.table-data-pagination ul.page-nums {
  padding: 0 10px;
  list-style: none;
}

.table-data-pagination ul.page-nums li {
  padding-right: 6px;
  cursor: pointer;
}

.table-data-pagination ul.page-nums li.active {
  font-weight: bold;
}

.release-library-middle-container .middle-container-bottom {
  margin-top: 0px;
}

.release-library-middle-container .dashboard-tables-wrapper table th {
  background: #ebebeb;
  color: #000;
}

.release-library-middle-container .dashboard-tables-wrapper {
  padding: 0;
  margin-top: 0;
}

/* release library table css ends */

.release-date-item .line {
  max-width: 20px;
  width: 100%;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative;
}

.release-month-wrapper .release-month {
  font-weight: bold;
  font-size: 14px;
}

.release-month-wrapper .release-num {
  font-weight: 600;
  font-size: 13px;
  display: block;
  min-height: 20px;
}

.release-date-timeline {
  margin: 15px 0 0;
  max-height: calc(100vh - 210px);
  overflow: auto;
}

.release-date-item {
  padding-bottom: 30px;
  cursor: pointer;
}

.release-date-item .line .round {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: solid 1px #babbbd;
  background: #babbbd;
  z-index: 0;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.release-date-item .line .history-line {
  width: 1px;
  height: 64px;
  background: #dcdee8;
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  top: 18px;
}

.release-date-item.current .line .round {
  background: #4582e1;
  border: solid 1px #4582e1;
}

.release-date-item:last-child .line .history-line {
  display: none;
}

/* .release-library-middle-container .dashboard-tables-wrapper {
  max-height: 70vh;
  overflow: auto;
} */

.release-library-checkbox-wrapper.form-checkbox-group {
  position: relative;
  padding: 15px 0 0;
}

.release-library-checkbox-wrapper.form-group label.form-checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: 24px;
  font-size: 12px;
}

.release-library-checkbox-wrapper input[type="checkbox"] {
  border: none;
  width: auto;
  position: absolute;
  left: 0;
  opacity: 0;
}

.release-library-checkbox-wrapper .form-checkbox .checkmark {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  position: absolute;
  background-color: transparent;
  border: 1px solid #000;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  transition: all 0.4s ease-in-out;
}

.release-library-checkbox-wrapper .form-checkbox input:checked ~ .checkmark {
  background-color: #000;
}

.release-library-checkbox-wrapper
  .form-checkbox
  input:checked
  ~ .checkmark::after {
  display: block;
}

.release-library-checkbox-wrapper .form-checkbox .checkmark::after {
  content: "";
  position: absolute;
  left: 4px;
  top: -1px;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;
}

.label-text {
  position: relative;
  top: -3px;
  font-weight: 600;
}

.library-outer-wrapper .library-left-container{
  margin-top: 100px;
  position: fixed;
  left: 0;
  top:0;
}

.library-outer-wrapper  .right-main-container{
  margin-left: 250px;
  margin-top: 100px;
}
.verify-popup input[type="text"],.verify-popup input[type="password"] {
  height: 35px;
  font-size: 12px;
}

.popup-min-bottom .blue-button.verify::after {
  /* background: url(../../assets/images/ic_tick-mark2-old.png) no-repeat 0 0;
  filter: brightness(0) saturate(100%) invert(88%) sepia(34%) saturate(2%) hue-rotate(178deg) brightness(109%) contrast(100%);
  background-size: 100%; */
}

.popup-min-bottom .blue-button.verify img{
  filter: brightness(0) saturate(100%) invert(88%) sepia(34%) saturate(2%) hue-rotate(178deg) brightness(109%) contrast(100%);
}

.verify-popup label {
    font-weight: bold;
    font-size: 13px;
    padding-bottom: 8px;
    display: inline-block;
}
