.searchWrapper {
  position: relative;
  max-width: 460px;
  width: 100%;
}

.searchWrapper::before {
  position: absolute;
  content: '';
  background:  url(../../assets/images/search_icon.png)
    no-repeat  0 0; 
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    background-size: 100%;
}

.searchWrapper input {
  width: 100%;
  font-size: 13px;
  line-height: 15px;
  border-radius: 10px;
  border: solid 1px #bababa;
  padding: 13px 10px 10px 35px;
  /* background: #fff url(../../assets/images/search_icon.png)
    no-repeat 1% center; */
  outline: 0;
  font-weight: 500;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover { 
    cursor:pointer; 
}

.searchWrapper input::-webkit-input-placeholder,
.searchWrapper input:-ms-input-placeholder,
.searchWrapper input::placeholder {
  color: #535353;
}

.nav-buttons {
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

.middle-container-header-left {
  /* align-items: center; */
  gap:3px;
  max-width: 57%;
  flex-direction: column;
}

.middle-container-header-left.dashboard-header-left {
  max-width: 50%;
}

.release-headings h2 {
  font-weight: bold;
  font-size: 20px;
}



