/* .popup-min.add-tags-popup {
  max-width: 520px;
}

.popup-min.add-tags-popup .tags-wrapper {
  max-height:unset;
  overflow: unset;
} */


.popup-min.add-tags-popup {
  max-width: 440px;
}

.add-tags-list-wrapper,.edit-tags-list-wrapper{
  /* margin: 20px 0; */
  /* padding: 0 8px; */
  margin-bottom:20px;
}

.search-tags input[type='text'],.edit-tags-list-wrapper input[type='text']{
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  border-radius: 15px;
  border: solid 1px #BABABA;
  outline: none;
  padding: 13px 10px 13px 10px;
  background: #fff;
  outline: 0;
  font-weight: normal;
}

.edit-tags-list-wrapper input[type='text']{
  max-width: 390px;
  border-radius: 6px;
  margin-left: 10px;
  height: 35px;
}
 .tag-color{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}

.add-tags-list-wrapper .tag-text {
  padding-left: 10px;
  font-size: 13px;
}

.add-tags-list-wrapper li,.edit-tags-list-wrapper li{
  padding: 20px 10px;
  /* border-bottom: solid 1px #d9dae0; */
  align-items: center;
  position: relative;
  cursor: pointer;
}

.edit-tags-list-wrapper li {
  padding: 12px 0 0;
  cursor: auto;
}

.edit-tags-list-wrapper li:first-child {
  padding: 0;
  padding-bottom: 5px;
}

.add-tags-list-wrapper li.active::after{
  position: absolute;
  content: '';
  background: url(../../assets/images/checked_icon_blue.png) no-repeat 0 0;
  width: 14px;
  height: 14px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.add-tags-list-wrapper li.active{
  background-color: #eaf2ff;
}

.add-tags-list-wrapper li:first-child,.edit-tags-list-wrapper li:first-child{
  /* border-top: solid 1px #d9dae0; */
}

.add-tags-list-wrapper li:last-child,.edit-tags-list-wrapper li:last-child{
  border-bottom: none;
}

.add-tags-popup .blue-button.apply{
  padding: 8px 14px 8px 14px;
}

.popup-min-bottom{
  align-items: center;
}

.button-text-only{
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.close-popup{
  position: absolute;
  right: 20px;
  top: 15px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url(../../assets/images/close-icon-grey.png);
  z-index: 1002;
}

.delete-tag{
  background: url(../../assets/images/delete_grey.png) no-repeat 0 0;
  width: 18px;
  height: 18px;
  background-size: 100%;
  margin-left: 8px;
  cursor: pointer;
}

.button-dotted {
  background: transparent;
  border: dashed 1px #000;
  padding: 8px 14px 8px 28px;
  color: #000;
  font-weight: normal;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  font-size: 14px;
  font-family: 'Roboto';
  text-align: center;
}

button.add-new.button-dotted {
  border: dashed 1px #33455F;
  height: 45px;
  background: #fff;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
}

/* .button-dotted.add-new span:after {
  background: url(../../assets/images/close-icon-grey.png) no-repeat 0 0;
  transform: rotate(45deg);
  background-size: 100%;
} */

.button-dotted span{
  position: relative;
}

.button-dotted span:after {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}

.add-tags-popup .button-dotted{
  width: 100%;
  /* margin-top: 20px; */
}

.add-tags-popup  .tag-color-main{
  border: dashed 1px transparent;
  display: flex;
  border-radius: 50%;
  padding: 3px;
  position: relative;
}

.add-tags-popup  .tag-color-main.clicked{
  border: dashed 1px #b3b3b3;
}

.tag-color-list{
  position: fixed;
  left: -45px;
  top: 65px;
  width: 35px;
  background: #fff;
  display: none;
  border-radius: 10px;
  padding: 10px;
  z-index: 1010;
}
.tag-color-list.show{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tag-color-list span:not(:last-child){
  margin-bottom: 10px;
  cursor: pointer;
}

.tag-color-list span.selected{
  border: solid 2px blue;
}

.tag-color-list::after {
  position: absolute;
  content: '';
  top: 12px;
  right: -7px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff;
}

.add-tag-btn{
  background: url(../../assets/images/plus_icon_grey.png) no-repeat 0 0;
  width: 20px;
  height: 20px;
  background-size: 100%;
  cursor: pointer;
}

.grey-button {
  background: #d9d9d9;
  border: solid 1px #d9d9d9;
  padding: 8px 14px 8px 28px;
  color: #000;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto';
}

.add-colorpicker{
  width: 20px !important;
  height: 20px !important;
  background-size: 100%;
  cursor: pointer;
  border: none !important;
  background: transparent url(../../assets/images/plus_icon_grey.png) no-repeat 0 0;
  background-size: 100%;
}

.add-colorpicker span{
  background-color: unset  !important;
  border: none !important;
}

.tag-color-list span div:first-child[class*="Popover"] {
  /* bottom: 35px; */
  top: 50% !important;
  transform:translateY(-50%) !important;
  left: 33px !important;
}

.tag-color-list span div [class*="Arrow"]::before ,
.tag-color-list span div [class*="Arrow"]::after {
  display: none;
}

.tag-color-list span div [class*="ColorPicker"] input{
  height: 25px;
  font-size: 12px;
  font-family: "Manrope";
  font-weight: 600;
  padding: 3px;
}
.edit-tags-list-wrapper input[type='text'].invalid-error {
  border: solid 1px rgb(255 0 0 / 40%);
}
.add-colorpicker .sketch-picker{
  position: absolute;
    left: 42px;
    top: -45px;
}
.edit-tags-list-wrapper li,.edit-tags-list-wrapper li:first-child {
  padding-right: 10px;
}
@media screen and (max-height:660px) {
  .add-tags-list-wrapper, .edit-tags-list-wrapper {
    max-height: 230px;
    margin: 15px 0;
  }
  .popup-min-bottom {
    /* padding: 10px 20px 10px; */
}
}
@media screen and (max-height:600px) {
  .add-tags-list-wrapper, .edit-tags-list-wrapper {
    max-height: 200px;
    margin: 15px 0;
  }
}
@media screen and (max-height:560px) {
  .add-tags-list-wrapper, .edit-tags-list-wrapper {
    max-height: 180px;
  }
}
   




