.dashboard-table-headings,
.dashboard-table-headings-left {
  align-items: center;
  /* justify-content: space-between; */
  gap: 15px;
}
.dashboard-table-headings-left {
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-grow: 1;
  display: flex;
}

.dashboard-top .dashboard-table-headings-left {
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
}

.dashboard-top .dashboard-table-headings {
  align-items: flex-end;
}

.dashboard-table-headings-left .dashboard-table-heading-tabs{
  gap: 10px;
  flex-wrap: wrap;
}

.dashboard-table-headings {
  align-items: center;
}
/* right side container */

.Filter-wrap .filter-tag-area {
  width: 100%;
  min-height: 65px;
  border: 1px solid var(--filter-textarea-border);
  margin-top: 15px;
  padding: 10px;
}

.filter-submit-btn {
  text-align: end;
  display: flex;
  justify-content: flex-end;
}

.filter-submit-btn .btn {
  /* padding:7px 20px 10px 20px; */
  width: 58px;
  border-radius: 8px;
  outline: none;
  margin: 0px 0 0 10px;
  transition: all 0.15s;
  height: 32px;
  font-size: 14px;
  font-family: "Roboto";
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-grey {
  background-color: var(--tab-border);
}

.btn-green {
  background-color: var(--button-green);
  color: var(--button-green-text);
}

.btn-apply {
  background-color: #33455f;
  color: #fff;
  border: solid 1px #33455f;
}

.btn-clear {
  background-color: #f9f9f9;
  color: #33455f;
  border: solid 1px #bababa;
}

.inbox-wrap {
  min-height: 100px;
  width: 100%;
  color: var(--tab-color);
  font-size: 12px;
}

.inbox-wrap div:not(:last-child) {
  border-bottom: solid 1px #cccc;
  padding-bottom: 5px;
}

.inbox-wrap div.inbox-text {
  display: flex;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
}

.inbox-wrap div.inbox-text:first-child {
  border-radius: 6px 6px 0 0;
}

.inbox-wrap div.inbox-text.unread {
  background: #ecf1ff;
}

.earnings-report-details {
  padding-top: 5px;
  gap: 15px;
  align-items: flex-start;
}

.earnings-report-details .prespective-wrap,
.earnings-report-details .progress-wrap {
  max-width: 36%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  padding: 15px 12px 8px 12px;
  border: solid 1px #bababa;
}

.earnings-report-details > div:last-child {
  flex: 1;
}

.subscriber-box {
  /* padding: 10px 12px; */
  /* border-radius: 10px; */
  /* border: solid 1px var(--tab-border); */
  /* margin: 15px 0 0; */
  display: flex;
  flex-direction: column;
}

.subscriber-box-item {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background: #fff;
  border: solid 1px #bababa;
  border-radius: 8px;
  padding: 15px 12px;
}

.subscriber-box-item h2{
  margin-bottom: 10px;
}

.subscriber-box-item-flex{
  gap: 12px;
  align-items: center;
}

.subscriber-box-item:not(:last-child) {
  margin-bottom: 15px;
}

.subscriber-box .round-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
}

.subscriber-box .round-icon.active,.subscriber-box .round-icon.new {
  background: #eaf2ff;
}

.subscriber-box .round-icon::after {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.subscriber-box .round-icon.active::after {
  background:  url(../../assets/images/subscribers_icon.png) no-repeat 0 0;
  background-size: 100%;
  width: 16px;
  height: 22px;
}

/* .subscriber-box .round-icon.new {
  background: #e9fed7 url(../../assets/images/new_subscriber.png) no-repeat
    center center;
} */

.subscriber-box .round-icon.new::after {
  background:  url(../../assets/images/new_subscriber.png) no-repeat 0 0;
  background-size: 100%;
  width: 22px;
  height: 22px;
}

.subscriber-box h4 {
  font-size: 14px;
  /* font-weight: 600; */
  /* padding: 5px 0 0px; */
}

.subscriber-box p {
  font-size: 13px;
}

.earnings-report-details img {
  object-fit: fill;
  width: 100%;
  /* height: 100%; */
  border-radius: 15px;
}

.release-graph,
.progress-graph {
  /* border: 1px solid var(--tab-border); */
  border-radius: 10px;
  margin: 15px 0 0;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.tableColumn.Tags {
  justify-content: center;
}

/* .tags-col span {
  width: 12px;
  height: 12px;
  margin: 0 5px 0 0;
  border-radius: 50%;
  text-align: center;
  display: block;
} */

.dashboard-table-headings span {
  font-size: 12px;
  text-align: center;
  /* padding: 5px 5px; */
  padding: 6px 10px 5px 10px;
  border-radius: 8px;
  border: 1px solid #cdcdcd;
  color:#535353;
  transition: all ease 0.3s;
  /* cursor: pointer; */
  background: #f9f9f9;
  font-weight: normal;
  /* width: 90px; */
}

.dashboard-table-headings span.checkmark{
  padding: 0;
}

.dashboard-table-headings span:hover,
.dashboard-table-headings span.active {
  /* border: 1px solid var(--primary-black);
  color: var(--primary-black); */
  background: #eaf2ff;
  color: #385a8b;
  border-color: #427fe0;
}

.dashboard-table-headings span.checkmark:hover,
.dashboard-table-headings span.checkmark.active {
  background: transparent;
}

.dashboard-table-headings button.add-Tab {
  border-radius: 50%;
  font-size: 30px;
  line-height: 35px;
  width: 30px;
  height: 30px;
  background: var(--primary-white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  position: relative;
  right: 10px;
}

/* .tags-col {
  max-width: 143px; */
/* width: 100%; */
/* justify-content: center; */
/* flex-wrap: wrap; */
/* } */

.prespective-wrap h2,
.subscribers-wrap h2,
.progress-wrap h2 {
  font-size: 16px;
  font-weight: 500;
  color: #151F2D;
}

.filter-tag-wrap h3,
.filter-tag-wrap label {
  font-size: 12px;
  /* font-weight: bold; */
}

.download-icon img,
.delete-icon img {
  max-width: 13px;
  cursor: pointer;
}

.scheduled-release-table .tags-col {
  max-width: unset;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.scheduled-release-table .tags-col span {
  width: 16px;
  height: 16px;
  margin: 0 7px 5px 0px;
}

.scheduled-release-table .tags-col span .tag-name-tooltip{
  position: fixed;
  transform: translateX(-44%);
}


.date-inputs {
  gap: 6px;
}

.filter-tags .form-group {
  margin-bottom: 12px;
  padding: 0;
}

.filter-tags .form-group label {
  margin-bottom: 6px;
  display: inline-block;
}

.filter-tags .form-group input[type="text"],
.filter-tags .form-group input[type="date"] {
  height: 30px;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  border: solid 1px #bababa;
  background: #fff;
}

.filter-tags .form-group textarea {
  height: 70px;
  padding: 2px 5px 5px 5px;
  font-size: 12px;
}

.filter-tags .form-group label.form-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-left: 30px;
  font-size: 12px;
}

.form-checkbox input[type="checkbox"] {
  border: none;
  width: auto;
  position: absolute;
  left: 0;
  opacity: 0;
}

.form-checkbox .checkmark {
  width: 17px;
  height: 17px;
  border-radius: 4px;
  position: absolute;
  background-color: transparent;
  border: 1px solid #bababa;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  transition: all 0.4s ease-in-out;
}

.form-checkbox input:checked ~ .checkmark{
  background-color: #33455F;
}

.filter-tags .form-checkbox .checkmark {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.filter-tags .form-checkbox .checkmark::after {
  left: 6px;
  top: 0px;
  width: 5px;
  height: 13px;
  border-color: #fff;
}

.reviewed-checkbox{
  display: inline-block;
  margin-right: 4px;
}

.form-checkbox input:checked ~ .checkmark::after {
  display: block;
}

.form-checkbox .checkmark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;
}

.filter-tags .form-group.form-checkbox-group {
  position: relative;
  align-items: center;
}

.filter-tags .form-group label.form-checkbox+span{
  /* padding-left: 25px; */
  display: flex;
  align-items: center;
  position: relative;
  font-weight: normal;
}

.unread .inbox-right {
  width: 11px;
  height: 10px;
  background-color: #456ddd;
  display: inline-block;
  border-radius: 50%;
}

.read .inbox-right {
  background: url(../../assets/images/checked_icon.png) no-repeat 0 0;
  background-size: 100%;
  width: 14px;
  height: 14px;
}

.reviewed img {
  max-width: 16px;
  position: relative;
  top: 3px;
  left: 2px;
}

.reviewed-checkbox img {
  max-width: 20px;
  margin-left: 3px;
  display: block;
}

.dashboard-tables-wrapper thead th {
  position: sticky;
  top: -2px;
}

.dashboard-content-main {
  flex-direction: column;
}

.dashboard-content-main {
  /* height: calc(100% - 195px); */
}

.dashboard-main{
  height: 100%;
  flex-direction: column;
  /* justify-content: space-between; */
}

.dashboard-top{
  background: #fff;
  padding: 20px 14px 5px 14px;
  border: solid 1px #bababa;
  border-radius: 10px;
  flex-grow: 1;
}


.dashboard-table-headings-left h3{
  color: #101010;
    /* font-weight: 600; */
    font-size: 12px;
    padding-bottom: 8px;
}

.th-headings{
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.filter-tag-multiselect-wrapper{
  position: relative;
}

.filter-tag-multiselect-selected{
  width: 100%;
  min-height: 32px;
  height: auto;
  border: solid 1px #bcbcbc;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.filter-tag-multiselect-wrapper.open .filter-tag-multiselect-selected{
  border-radius: 6px 6px 0 0;
}

.filter-tag-multiselect-dropdown-arrow{
  width: 24px;
  height: 24px;
  border-left: solid 1px #CCCCCC;
  position: relative;
  cursor: pointer;
}

.filter-tag-multiselect-dropdown-arrow::after{
  position: absolute;
  content: '';
  background-size: 100%;
  background: url(../../assets/images/down_grey_sm.png) no-repeat 0 0;
  width: 15px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: all ease .3s;
}

.filter-tag-multiselect-wrapper.open .filter-tag-multiselect-dropdown-arrow::after {
  transform: translate(-50%,-50%) rotate(180deg);
}

.filter-tag-multiselect-selected-items{
  flex-grow: 1;
}

.filter-tag-multiselect-dropdown-list{
  position: absolute;
  top: 32px;
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-top: none;
  width: 100%;
  padding: 8px 12px;
  border-radius: 0 0 3px 3px;
  display: none;
  z-index: 2;
}

.filter-tag-multiselect-wrapper.open .filter-tag-multiselect-dropdown-list{
  display: block;
}

.filter-tag-multiselect-dropdown-search{
  position: relative;
}

.filter-tag-multiselect-dropdown-search::after{
  position: absolute;
  content:'';
  background: url(../../assets/images/search_icon.png) no-repeat 0 0;
  background-size: 100%;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
}

.filter-tags .form-group .filter-tag-multiselect-dropdown-search input[type="text"]{
  padding: 3px 5px 5px 30px;
  height: 35px;
}

.filter-tag-multiselect-taglist{
  margin-top: 10px;
}

.filter-tag-multiselect-taglist-item{
  padding: 7px 0;
  border-bottom: solid 1px #F8F8F8;
  cursor: pointer;
}

.filter-tag-multiselect-taglist-item.no-filter-tags{
  cursor: auto;
}

.filter-tag-multiselect-taglist-item-left{
  gap: 6px;
  align-items: center;
  flex-grow: 1;
}

.filter-tag-multiselect-taglist-item-added{
  color:#969696;
  padding-right: 10px;
}

.filter-tag-multiselect-taglist-item:last-child{
  border-bottom: none;
}

.filter-tag-multiselect-taglist-item .filter-tag-colour{
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: solid 1px transparent;
  flex-shrink: 0;
}

.filter-tag-multiselect-taglist-item .filter-tag-text{
  font-size: 12px;
}

.filter-tag-multiselect-selected-items{
  gap: 6px;
  padding: 7px 8px;
  align-items: center;
}


.filter-tag-multiselect-selected-item{
  background: #E7E7E7;
  padding: 2px 4px;
  border-radius: 1px;
  gap: 4px;
  font-size: 10px;
  color: #424242;
  align-items: center;
}

.filter-tag-multiselect-tagdetails .tag-color{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
  
.filter-tag-multiselect-tagdetails {
  align-items: center;
  gap: 4px;
}

.filter-tag-multiselect-close{
  background: url(../../assets/images/close-icon-grey.png);
  background-size: 100%;
  width: 10px;
  height: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

.filter-tag-multiselect-more-items{
  font-size: 10px;
  color: #797979;
  position: relative;
  cursor: pointer;
}

.filter-tag-multiselect-more-items::after{
  position: absolute;
  content: '';
  background: #797979;
  height: 1px;
  top:calc(100% + 1px);
  width: 100%;
  left: 0;

}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  background-color: #fff !important;
  padding: 5px;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  padding-bottom: 6px  !important;
  font-size: 14px !important; 
  color: #8A8A8A !important;
}
.react-datepicker__month-option.react-datepicker__month-option--selected_month{
  color: #000 !important;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: transparent !important;
}

.react-datepicker__navigation--years-previous,.react-datepicker__navigation--years-upcoming{
  background: url('../../assets/images/down_arrow_grey.png') no-repeat center !important;
  width: 12px;
  height: 12px;
}

.react-datepicker__navigation--years-upcoming{
  transform: rotate(180deg);
}

.scheduled-release-date,.scheduled-release-time{
  color:#535353;
}

.dashboard-max-height {
  /* max-height:calc(100vh - 250px) ; */
  min-height:calc(100vh - 250px) ; ;
}

.middle-container-bottom.dashboard-middle-container{
  padding: 0px 15px 0px 15px;
}
.additional-filter-multiselect .filter-tag-multiselect-selected-item{
  word-break: break-word;
}

.additional-filter-multiselect .filter-tag-multiselect-selected-item.sm{
  max-width: 48%;
}

.additional-filter-multiselect .filter-tag-multiselect-more-items,
.additional-filter-multiselect .filter-tag-multiselect-dropdown-arrow
{
  flex-shrink: 0;
}

.additional-filter-multiselect-left{
  max-width: 95%;
  display: flex;
  gap: 6px;
  align-items: center;
}

.additional-filter-multiselect  .filter-tag-multiselect-dropdown-list{
  top: 100%;
}

@media screen and (max-width:1400px) {
  .dashboard-content-main {
   /* height: calc(100% - 220px); */
  } 
  .dashboard-max-height {
    /* max-height:calc(100vh - 185px) ; */
    /* min-height:calc(100vh - 185px)  ; */
  }
}
