.blue-button.submit::after {
    background: url(../../assets/images/check_white.png) no-repeat 0 0;
    background-size: 100%;
}

.popup-min.otp-form-popup {
    max-width: 350px;
}

.popup-min.otp-form-popup .form-group input[type='text']{
    height: unset;
    padding: 5px 12px;
    text-align: center;
    font-weight: 600;
    border: none;
    border-bottom: solid 1px #d6d7da;
    background: transparent;
    border-radius: unset;
}

.otp-form-popup label,.otp-form-popup span.resend {
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 8px;
    display: inline-block;
}

.otp-form-popup span.resend {
    padding: 15px 0 15px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 3px;
}

.otp-form-popup span.resend img {
    width: 18px;
}

.otp-form-popup .form-group{
    padding: 0;
}

.otp-form-popup .form-group .flex{
    gap: 15px;
}

.otp-form-popup .close-button-no-icons {
    background: #F9F9F9;
    border: solid 1px #EBEBEB;
    padding: 8px 14px 8px 14px;
    color: #33455F;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    text-align: left;
    outline: none;
    font-size: 14px;
    font-family: 'Roboto';
}

.otp-form-popup .popup-min-bottom{
    justify-content: space-between;
}