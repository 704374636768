.page-center {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}
.flex {
    display: flex;
}

.release-notes-wrapper {
    /* background: #fff; */
    box-sizing: border-box;
    min-height: 100vh;
}


.reportspam-main {
    max-width: 45%;
    width: 100%;
    /* background: #fff; */
    border-radius:15px;
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px; */
    padding: 30px 25px;
    /* align-items: center; */
}

.reportspam-form-wrapper textarea{
    height: 140px;
    background: #fff;
    padding: 10px;
    font-family: 'Roboto';
    font-size: 12px;
}

.reportspam-form-wrapper .character-count{
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}

.reportspam-form-wrapper .character-count span{
  background: #fff;
  padding: 5px 8px ;
  border-radius: 6px;
}

.report-spam-left{
    flex-shrink: 0;
    width: 40%;
    display: flex;
    justify-content: center;
    position: relative;
    top: -12px;
}

.reportspam-form-wrapper {
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.reportspam-main h1 {
    font-size: 19px;
    font-weight: 600;
}

.reason-textarea-wrapper{
  padding-left: 24px;
  width: 85%;
}

.reportspam-main h2 {
    font-size: 18px;
    font-weight: normal;
}

.reportspam-main.sucess-msg-wrapper{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 60px 25px;
}

.reportspam-form{
    /* background: #FAFAFA80; */
    padding: 25px 25px 0 25px;
    border-radius: 5px;
    font-size: 12px;
}

.reportspam-form .blue-button {
    background: #33455F;
    border: solid 1px #33455F;
    padding: 12px 25px 8px 29px;
    color: var(--primary-white);
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    text-align: center;
    outline: none;
    font-size: 14px;
    font-family: "Roboto";
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .report-btn-wrapper{
    display: flex;
    justify-content: center;
  }

  .reportspam-form .blue-button span{
    position: relative;
  }

  .reportspam-form .blue-button span::before{
    position: absolute;
    content: '';
    /* background: url(../../assets/images/spam_icon.png) no-repeat 0 0; */
    background-size: 100%;
    width: 17px;
    height: 15px;
    left: -22px;
    top: 51%;
    transform: translateY(-50%);
  }

.reportspam-form .form-group:first-child{
    padding-top: 0;
}


.reportspam-form .filter-tag-multiselect-wrapper{
    position: relative;
  }
  
.reportspam-form .filter-tag-multiselect-selected{
    width: 100%;
    min-height: 32px;
    height: auto;
    border: solid 1px #bcbcbc;
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
  
.reportspam-form .filter-tag-multiselect-wrapper.open .filter-tag-multiselect-selected{
    border-radius: 6px 6px 0 0;
  }
  
  .reportspam-form .filter-tag-multiselect-dropdown-arrow{
    width: 24px;
    height: 24px;
    border-left: solid 1px #CCCCCC;
    position: relative;
    cursor: pointer;
  }
  
  .reportspam-form .filter-tag-multiselect-dropdown-arrow::after{
    position: absolute;
    content: '';
    background-size: 100%;
    background: url(../../assets/images/down_grey_sm.png) no-repeat 0 0;
    width: 15px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all ease .3s;
  }
  
  .reportspam-form .filter-tag-multiselect-wrapper.open .filter-tag-multiselect-dropdown-arrow::after {
    transform: translate(-50%,-50%) rotate(180deg);
  }
  
  .reportspam-form .filter-tag-multiselect-selected-items{
    flex-grow: 1;
  }
  
  .reportspam-form .filter-tag-multiselect-dropdown-list{
    position: absolute;
    top: 35px;
    background: #fff;
    border: solid 1px rgba(0, 0, 0, 0.2);
    border-top: none;
    width: 100%;
    padding: 8px 12px;
    border-radius: 0 0 3px 3px;
    display: none;
    z-index: 2;
  }
  
  .reportspam-form .filter-tag-multiselect-wrapper.open .filter-tag-multiselect-dropdown-list{
    display: block;
  }
  
  .reportspam-form .filter-tag-multiselect-dropdown-search{
    position: relative;
  }
  
  .reportspam-form .filter-tag-multiselect-dropdown-search::after{
    position: absolute;
    content:'';
    background: url(../../assets/images/search_icon.png) no-repeat 0 0;
    background-size: 100%;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
  }
  
  .reportspam-form .filter-tags .form-group .filter-tag-multiselect-dropdown-search input[type="text"]{
    padding: 3px 5px 5px 30px;
    height: 35px;
  }
  
  .reportspam-form .filter-tag-multiselect-taglist{
    margin-top: 0px;
  }
  
  .reportspam-form .filter-tag-multiselect-taglist-item{
    padding: 7px 0;
    border-bottom: solid 1px #F8F8F8;
    cursor: context-menu;
  }

  .reportspam-main-wrapper{
    background: #e8e8e8;
    min-height: calc(100vh - 88px);
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  
  .reportspam-form .filter-tag-multiselect-taglist-item.no-filter-tags{
    cursor: auto;
  }
  
  .reportspam-form .filter-tag-multiselect-taglist-item-left{
    gap: 6px;
    align-items: center;
    flex-grow: 1;
  }
  
  .reportspam-form .filter-tag-multiselect-taglist-item-added{
    color:#969696;
    padding-right: 10px;
  }
  
  .reportspam-form .filter-tag-multiselect-taglist-item:last-child{
    border-bottom: none;
  }
  
  .reportspam-form .filter-tag-multiselect-taglist-item .filter-tag-colour{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: solid 1px transparent;
    flex-shrink: 0;
  }
  
  .reportspam-form .filter-tag-multiselect-taglist-item .filter-tag-text{
    font-size: 12px;
    cursor: pointer;
  }
  
  .reportspam-form .filter-tag-multiselect-selected-items{
    gap: 6px;
    padding: 7px 8px;
    align-items: center;
  }
  
  
  .reportspam-form .filter-tag-multiselect-selected-item{
    background: transparent;
    padding: 2px 4px;
    border-radius: 1px;
    gap: 4px;
    font-size: 12px;
    color: #424242;
    align-items: center;
  }
  
  
    
  .reportspam-form .filter-tag-multiselect-tagdetails {
    align-items: center;
    gap: 4px;
  }
  
  .reportspam-form .filter-tag-multiselect-close{
    background: url(../../assets/images/close-icon-grey.png);
    background-size: 100%;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
  
  .reportspam-form .filter-tag-multiselect-more-items{
    font-size: 12px;
    color: #000;
    position: relative;
    cursor: pointer;
  }
  
  .reportspam-form .filter-tag-multiselect-more-items::after{
    position: absolute;
    content: '';
    background: #797979;
    height: 1px;
    top:calc(100% + 1px);
    width: 100%;
    left: 0;
  
  }

.feedback-in {
    max-width: 655px;
    width: 100%;
    text-align: center;
    padding-top: 90px;
}

.feedback-in.unsubscribe {
    max-width: 655px;
    width: 100%;
    text-align: center;
    padding-top: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}

.verify-main .feedback-in.unsubscribe{
    justify-content: center;
}

.feedback-in h3 {
    font-size: 36px;
    font-weight: 600;
}

.feedback-in p {
    font-size: 24px;
    font-weight: 300;
    padding: 30px 0 40px;
}

.unsubscribing-form {
    max-width: 335px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
}


.unsubscribing-form form {
    width: 100%;
}

.unsubscribing-form input[type='text'], .unsubscribing-form input[type='email'] {
    -webkit-appearance: none;
    width: 100%;
    padding: 5px 10px 5px 25px;
    height: 50px;
    color: #000;
    border-radius: 8px;
    border: solid 1px #dedede;
    font-size: 20px;
    font-family: 'Euclid Circular A';
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
}

.release-description-btns-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 25px;
}

.feedback-btns-wrapper {
    justify-content: center;
}

.feedback-btns-wrapper .btn-action, .feedback-btns-wrapper .btn-cancel {
    text-transform: uppercase;
}

.release-description-btns-wrapper button, .popup-btns-wrapper button {
    margin-right: 10px;
}

.btn-action {
    padding: 5px 20px;
    font-size: 18px;
    background: #333eb3;
    color: #fff;
    border: solid 1px #333eb3;
    outline: none;
    border-radius: 8px;
    height: 40px;
    cursor: pointer;
    font-family: 'Euclid Circular A';
}

.copyright {
    flex-grow: 1;
    display: flex;
    align-items: center;
    color: #a6a7b2;
    font-size: 16px;
    font-weight: 300;
    padding-top: 20px;
}

.message-page-main-wrapper header{
    background: #33455F;
    padding: 10px 0;
    color: #fff;
}

.message-page-main-wrapper footer{
    background: #33455F;
    padding: 9px 0;
    color: #fff;
    text-align: center;
}

.message-page-center {
    max-width: 1440px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.message-page-main-wrapper header .logo-wrapper {
    display: flex;
    gap: 6px;
    align-items: flex-start;
}

.release-notes-wrapper.verify-main {
    min-height: calc(100vh - 87px);
}

.message-page-main-wrapper{
    display: flex;
    flex-direction: column;
}

.verify-main  .release-notes-main.feedback-main-wrapper{
    min-height: unset;
}

.message-page-main-wrapper footer .copyright{
    padding-top: 0;
    font-size: 12px;
    justify-content: center;
}

.unsubscribe-radio-container {
  display: block;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 0px;
  font-weight: 600;
}

.unsubscribe-option {
  align-items: center;
  margin-bottom: 6px;
}

.unsubscribe-option .option-label{
  display: inline-block;
  padding-left: 25px;
}

.unsubscribe-option-wrapper{
  padding-top: 10px;
}


.unsubscribe-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}


.unsubscribe-radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #fff;
  border-radius: 50%;
  border: solid 1px #000;
}

.unsubscribe-radio-container input:checked ~ .checkmark {
  background-color: #fff;
  border: solid 1px #000;
}

.unsubscribe-radio-container input:checked ~ .checkmark::after {
  display: block;
}
.unsubscribe-radio-container .checkmark::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  content: "";
  position: absolute;
  display: none;
}

@media screen and (max-width: 1640px){
    .page-center {
        max-width: 100%;

    }
}


@media screen and (max-width: 1400px){
    .release-notes-wrapper {
        min-height: 100vh;
    }

    .release-notes-main.feedback-main-wrapper {
        min-height: calc(100vh - 80px);
    }

    .feedback-in {
        padding-top: 35px;
    }

    .feedback-in h3 {
        font-size: 25px;
    }

    .feedback-in p {
        font-size: 18px;
        padding: 10px 0 15px;
    }

    .unsubscribing-form input[type='text'], .unsubscribing-form input[type='email'] {
        font-size: 16px;
        height: 45px;
    }

    .release-notes-wrapper .release-description-top .form-group select, .btn-action, .btn-cancel {
        font-size: 16px;
    }

    .copyright {
        font-size: 14px;
    }
}

.processing-message {
  font-size: 14px;
  color: #666;
  margin-top: 15px;
  text-align: center;
}