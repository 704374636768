.searchWrapper.tags-searchwrapper {
  max-width: 500px;
}

.feedback-middle-container
  .feedback-listing-wrapper.dashboard-tables-wrapper.tags-listing-table {
  max-height: calc(100vh - 200px);
  margin-top: 10px;
}

.form-group label.form-checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: 25px;
  font-size: 12px;
  /* font-weight: 600; */
}

.feedback-middle-container
  .dashboard-tables-wrapper
  .form-group
  label.form-checkbox {
  padding-left: 20px;
  /* align-items: center; */
}

.tag-name-td .edit-text {
  /* padding-left: 22px; */
}

.feedback-table-actions-wrapper {
  gap: 30px;
  align-items: center;
}

.form-checkbox-group {
  position: relative;
}

.feedback-table-actions-wrapper .form-group,
.feedback-middle-container .dashboard-tables-wrapper .form-group {
  padding: 0;
}

.feedback-middle-container .form-checkbox .checkmark {
  border: 1px solid #bababa;
  padding: 0;
}

.feedback-middle-container .form-checkbox .checkmark {
  width: 15px;
  height: 15px;
  border-radius: 4px;
}

.feedback-middle-container .form-checkbox .checkmark::after {
  left: 4px;
  top: -1px;
}

.form-group select {
  font-size: 12px;
  padding: 8px 8px;
}

.form-group select {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border: solid 1px #e2e4e9;
  border-radius: 4px;
  font-family: 'Roboto';
  background: #f3f3f3 url(../../assets/images/down_arrow_grey.png) no-repeat 95%
    center;
  outline: none;
}

.feedback-actions-dropdown .form-group select {
  max-width: 150px;
}

.feedback-actions-dropdown .flex {
  align-items: center;
  gap: 10px;
}

.feedback-actions-dropdown {
  flex-grow: 1;
}

.dashboard-tables-wrapper table tr td .release-name-col-details p {
  font-size: 12px;
  font-weight: normal;
}

.feedback-middle-container .dashboard-tables-wrapper table tr td {
  /* vertical-align: middle; */
}

.feedback-middle-container .dashboard-tables-wrapper table tr td,
.feedback-middle-container .dashboard-tables-wrapper table tr td:first-child p {
  font-size: 12px;
}

.dashboard-tables-wrapper table tr td:first-child {
  /* font-weight: bold; */
  font-size: 12px;
}

.feedback-middle-container .dashboard-tables-wrapper table tr td:first-child p {
  padding: 4px 0 0;
}

.smiley-wrapper img {
  width: 20px;
  display: block;
}

.smiley-wrapper {
  gap: 5px;
  justify-content: flex-start;
}

.smiley-wrapper span {
  flex-shrink: 0;
}

.feedback-middle-container .actions-dropdown {
  font-size: 14px;
  /* font-weight: 600; */
}

.feedback-right-container .feedback-filter-wrapper {
  border: solid 1px #d9dae0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.feedback-right-container .feedback-filter-wrapper div.selected {
  background: #eaf2ff;
}

.feedback-right-container .feedback-filter-wrapper div:not(:last-child) {
  border-right: solid 1px #d9dae0;
}

.feedback-right-container .feedback-filter-wrapper div {
  cursor: pointer;
}

.feedback-right-container .feedback-filter-wrapper div > span {
  padding: 5px 10px;
  gap: 3px;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  flex-direction: column;
  display: flex;
  min-width: unset;
}

.feedback-right-container .feedback-filter-wrapper div > span img {
  width: 18px;
}

/* .feedback-right-container .filter-tags {
    max-height: calc(100vh - 120px);
    overflow: auto;
} */

.release-name-col-details {
  /* font-weight: 600; */
}

.reply-popup .textarea-form-wrapper textarea {
  height: 100px;
  padding: 10px;
}

.read-status img {
  width: 17px;
}

.read-status {
  flex-shrink: 0;
}

/* tag popup css starts */

.popup-min {
  border-radius: 7px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  color: #000;
  box-sizing: border-box;
  /* max-height: calc(100vh - 200px); */
  width: 100%;
  /* min-height: 150px; */
  transition: all ease 0.3s;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29);
  padding: 10px 10px;
  /* overflow: auto; */
}

.popup-min.reply-popup,
.popup-min.forward-popup {
  max-width: 520px;
}

.cross-icon {
  cursor: pointer;
}

.popup-min h2 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  /* margin-bottom: 10px; */
  word-break: break-word;
  /* border-bottom: solid 1px #EBEBED;
    padding-bottom: 5px; */
}

.tags-wrapper {
  max-height: calc(100vh - 350px);
  /* max-height: 420px; */
  overflow: auto;
}

.popup-min-top {
  padding: 0;
  border-bottom: solid 1px #ebebed;
}

.popup-min-bottom {
  /* padding: 10px 0 0; */
  justify-content: flex-end;
  gap: 15px;
}

.popup-min-bottom-left {
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
}

.popup-min-bottom .blue-button {
  background: #33455f;
  border: solid 1px #33455f;
  padding: 8px 14px 8px 14px;
  color: var(--primary-white);
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  font-size: 14px;
  font-family: 'Roboto';

  /* margin-right: 5px; */
}

.popup-min-bottom .close-button {
  background: #f9f9f9;
  border: solid 1px #ebebeb;
  padding: 8px 14px 8px 28px;
  color: #33455f;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  font-size: 14px;
  font-family: 'Roboto';
}

.popup-min-bottom .close-button-no-icons {
  background: #f9f9f9;
  border: solid 1px #ebebeb;
  padding: 8px 14px 8px 14px;
  color: #33455f;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  font-size: 14px;
  font-family: 'Roboto';
}

.popup-min-bottom .blue-button:last-child {
  margin-right: 0px;
}

.popup-min-bottom .close-button::after {
  background: url(../../assets/images/close-icon-black.png) no-repeat 0 0;
  background-size: 100%;
  width: 12px;
  height: 12px;
}

.add-tags-popup .popup-min-bottom .blue-button.save::after {
  background: none;
  /* background-size: 100%; */
}

/* .popup-min-bottom .grey-button.save::after {
    background: url(../../assets/images/save_white.png) no-repeat 0 0;
    background-size: 100%;
    filter: invert(1);
} */

.popup-min-bottom .blue-button:after,
.popup-min-bottom .grey-button:after,
.popup-min-bottom .close-button::after {
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.popup-min-bottom .close-button::after {
  width: 12px;
  height: 12px;
}

.feedback-main .nav-buttons {
  margin-left: 20px;
}

/* tag popup css ends */

.filter-tag-wrap .multi-selectbox-container {
  width: 100%;
}

.feedback-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 120px;
  word-break: break-word;
}

.feedback-text.full-text {
  overflow: unset;
  display: inline;
  -webkit-line-clamp: unset;
  line-clamp: unset !important;
  -webkit-box-orient: unset;
}

.smiley-wrapper span.selected {
  /* border: solid 1px #000; */
  border-radius: 50%;
}

.forward-popup .form-group input[type='text'] {
  height: 35px;
  font-size: 12px;
}

.forward-popup .form-group label {
  /* font-weight: bold; */
  margin-bottom: 6px;
  display: inline-block;
}

.multi-email-wrapper {
  display: flex;
  gap: 5px;
  background-color: var(--tab-border);
  padding: 2px 8px 5px;
  border-radius: 10px;
}

.feedback-right-container .feedback-filter-wrapper div {
  flex-grow: 1;
}

.form-group label.form-checkbox + span {
  /* padding-left: 25px; */
  /* font-weight: 600; */
  word-break: break-word;
  color: #535353;
}

.social-media-checkbox-wrapper label.form-checkbox + span {
  padding-left: 0;
  position: relative;
  top: 2px;
  left: -2px;
}

.tags-heading {
  margin-bottom: unset;
}

.dashboard-table-headings.tags-heading {
  justify-content: space-between;
  align-items: center;
}

.tags-heading .add-Tab {
  right: unset;
  left: 97%;
}

.tags-listing-table .tag-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
}

.edit-tags-list-wrapper .tag-color {
  cursor: pointer;
}

.edit-text input[type='text'] {
  border: none;
  border-bottom: solid 1px #8d8e98;
  width: 100%;
  max-width: 200px;
  display: none;
  background: transparent;
  font-size: 12px;
  height: unset;
  padding: 0px;
  border-radius: 0;
}

.edit-text.edit-text-visible input[type='text'] {
  display: block;
}
.tag-name-td {
  /* justify-content: center; */
  align-items: center;
}

.dashboard-tables-wrapper.tags-listing-table table tr td:first-child {
  font-weight: normal;
  font-size: 12px;
}

.tag-span {
  display: none;
}

.tag-list {
  -webkit-box-shadow: 0px 7px 25px 0px rgb(207 201 201 / 75%);
  -moz-box-shadow: 0px 7px 25px 0px rgba(207 201 201 / 75%);
  box-shadow: 0px 7px 25px 0px rgb(207 201 201 / 75%);
}

.tags-listing-table input[type='text'].invalid-error {
  border-bottom: solid 1px rgb(255 0 0 / 40%);
}

.tagslist-main-wrapper {
  border: solid 1px #00000033;
  border-radius: 7px;
  padding: 10px;
}

.dashboard-table-headings.tags-heading .form-checkbox span.check-text {
  border: none;
  background: transparent;
  padding: 0;
  color: #535353;
}

.tags-heading .form-group.form-checkbox-group {
  padding: 0;
}

.tags-heading .form-group label.form-checkbox + span {
  font-weight: normal;
}

.dashboard-table-headings.tags-heading span {
  border: none;
  background: transparent;
}

.dashboard-table-headings.tags-heading .form-group label.form-checkbox {
  padding-left: 26px;
}

.dashboard-table-headings.tags-heading
  .form-checkbox
  input:checked
  ~ .checkmark,
.feedback-listing-wrapper .form-checkbox input:checked ~ .checkmark {
  background-color: #33455f;
}

.feedback-listing-wrapper .form-checkbox .checkmark::after {
  border-color: #fff;
}

.dashboard-table-headings.tags-heading .form-checkbox .checkmark {
  border-radius: 4px;
}

.dashboard-table-headings.tags-heading .form-checkbox .checkmark::after {
  border-color: #fff;
}

.taglist-select-action select {
  width: 130px;
  background: #f3f3f3;
  height: 32px;
  border-radius: 4px;
  font-family: 'Roboto';
  padding: 7px 20px 7px 8px;
  font-size: 14px;
  outline: none;
  z-index: 2;
  position: relative;
  background: url(../../assets/images/down.png) no-repeat 93% center;
}

.tags-heading-left {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 8px;
}

.tags-listing-table tr th:first-child {
  width: 50%;
}
