.confirmation-popup {
  max-width: 520px;
}
.confirmation-popup .popup-min-top {
  border-bottom: none;
}
.confirmation-popup .popup-min-bottom .blue-button.save::after {
  /* background: url(../../assets/images/checked_icon_white.png) no-repeat 0 0; */
  background-size: 100%;
}
/* .popup-min-bottom .blue-button.confirmation-icon-padding {
  padding-left: 28px;
} */
