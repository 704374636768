/* @font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Light.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/Roboto-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("./assets/fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Roboto-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Black.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --primary-white: #ffffff;
  --secondary-white: #fefefe;
  --primary-black: #111;
  --body-black: #161824;
  --active-grey: #1d1d1d;
  --menu-color: #a7a7a7;
  --dark-white: #929292;
  --light-black: #68696d;
  --button-grey: #f0f2f6;
  --button-violet: #7c79cd;
  --button-blue: #69a5ff;
  --button-green: #dcfdf7;
  --button-green-text: #39baa3;
  --status-green: #4fbe39;
  --button-red: #ef1844;
  --table-innerblack: #4e536e;
  --table-headerblack: #464d65;
  --table-grey: #f2f5f9;
  --border-grey: #f0f3f8;
  --heading-text-black: #030303;
  --text-grey: #252525;
  --input-border: #787878;
  --placeholder-text: #535353;
  --right-grey: #f9f9f9;
  --search-grey: #f2f2f2;
  --tab-color: #7b7b7b;
  --tab-border: #e1e1e1;
  --published: #9fc45d;
  --scheduled: #da8c60;
  --draft: #afa9a8;
  --delete-red: #ff2d55;
  --filter-tab-border: #eceded;
  --filter-textarea-border: #ededed;
}

html,
body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto';
  color: #101010;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
}

a,
a:link,
a:visited {
  outline: none;
  text-decoration: none;
  -moz-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

img {
  border: none;
  margin: 0px;
  max-width: 100%;
}

h1 {
  font-family: 'Roboto';
  font-weight: normal;
  font-size: 24px;
  margin: 0px;
  padding: 0px;
}

h2 {
  font-family: 'Roboto';
  font-weight: normal;
  font-size: 24px;
  margin: 0px;
  padding: 0px;
}

h3,
h4,
h5,
h6 {
  font-family: 'Roboto';
  font-weight: normal;
  font-size: 18px;
  margin: 0px;
  padding: 0px;
}

form,
input,
select,
option textarea {
  margin: 0px;
  padding: 0;
  font-family: 'Roboto';
  -webkit-appearance: none;
  appearance: none;
}

p {
  margin: 0px;
  padding: 0;
}

textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time {
  display: block;
  margin: 0px;
  padding: 0;
}

.customClear {
  clear: both;
  font-size: 0em;
  line-height: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
}

* {
  box-sizing: border-box;
}

.outer-wrapper {
  display: flex;
  position: relative;
  min-height: 100vh;
}

.middle-container {
  flex-grow: 1;
  /* transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); */
  display: flex;
  flex-direction: column;
}

.right-container {
  max-width: 340px;
  width: 100%;
  background: #e8e8e8;
  /* transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1); */
  padding: 25px 15px 15px 15px;
  border-left: solid 1px #bababa;
  flex-shrink: 0;
  /* position: relative;
  z-index: 3; */
}

.flex {
  display: flex;
}

.middle-container-bottom {
  /* padding: 25px 24px; */
  padding: 0px 15px 15px 15px;
  flex-direction: column;
  flex-grow: 1;
}

.form-group input[type='text'],
.form-group textarea,
.form-group input[type='date'],
.form-group input[type='email'],
.form-group input[type='password'] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-family: 'Roboto';
  background: #fff;
  color: #101010;
  font-weight: normal;
}

.right-container h4 {
  font-size: 16px;
  font-weight: bold;
  color: #151f2d;
  /* padding-left: 8px; */
}

.right-container-headings {
  align-items: center;
  justify-content: space-between;
}

.right-container.open {
  max-width: 610px;
}

.show-md {
  display: block;
}

.hidden-md {
  display: none;
}

.bg-white {
  background: #fff;
}

.bg-grey-new {
  background: #e8e8e8;
}

.nav-buttons span {
  margin-right: 30px;
  color: #fff;
  cursor: pointer;
  width: 20px;
}

.nav-buttons span:last-child {
  margin-right: 0px;
}

/* fresh Coding starts */

/* right side container items */

.innerContainer {
  /* padding: 10px 12px; */
  /* background: #fff; */
  /* border-radius: 10px; */
  display: flex;
  align-items: flex-start;
  margin: 15px 0 0px;
  /* border: solid 1px var(--tab-border); */
}

.filter-tag-wrap {
  width: 100%;
}

.functional-box {
  flex-direction: column;
  padding: 15px 15px;
  background: #fff;
  border: solid 1px #bababa;
  border-radius: 10px;
  /* padding-top: 50px; */
}

.functional-box:not(:last-child) {
  margin-bottom: 15px;
}

.innerContainer.Scheduled-Release {
  /* padding: 10px; */
}

input[type='text'],
input[type='email'],
input[type='password'] {
  font-weight: 300;
  font-size: 18px;
  width: 100%;
  border: solid 1px var(--input-border);
  border-radius: 2px;
  height: 55px;
  padding: 8px 15px;
}

.form-group {
  padding: 20px 0 0;
}

input[type='text']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='text']:-ms-input-placeholder,
input[type='email']:-ms-input-placeholder,
input[type='password']:-ms-input-placeholder,
input[type='text']::placeholder,
input[type='email']::placeholder,
input[type='password']::placeholder {
  color: var(--placeholder-text);
}

.logo-icon {
  /* color: var(--menu-color); */
  color: #fff;
  display: flex;
}

.logo-icon h1 {
  /* font-size: 22px; */
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 15px;
  cursor: pointer;
}

.logo-icon small {
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.logo-icon p {
  font-size: 12px;
  font-weight: 500;
  padding-top: 3px;
  pointer-events: none;
}

.right-main-container {
  display: flex;
  flex-grow: 1;
  margin-left: 270px;
}

.text-left,
.dashboard-tables-wrapper table td.text-left {
  text-align: left;
}

.text-center,
.dashboard-tables-wrapper table td.text-center {
  text-align: center;
}

.right-container-heading-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}

.middle-container-top {
  padding: 25px 15px 15px;
  /* border-bottom: solid 1px #d9dae0; */
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.middle-container-top h1 {
  font-weight: bold;
  word-break: break-word;
}

.disabled-cls {
  opacity: 0.5;
  pointer-events: none;
}

span.error-message {
  padding: 5px 0 0;
  color: #f83f4f;
  font-size: 12px;
  display: block;
}

.create-release-texteditor .ck-editor {
  width: 100% !important;
}

.dl-listing-wrapper td.verfication-status {
  font-size: 16px;
  font-weight: normal;
}

.verfication-status .flex {
  align-items: center;
  padding-left: 25px;
}

.list-actions {
  display: flex;
}

.list-actions div {
  /* width: 30px;
  height: 30px; */
  border-radius: 25px;
  display: flex;
  /* border: solid 1px #000; */
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}

.list-actions div:last-child {
  margin-right: 0px;
}

.list-actions div.resend:before {
  position: absolute;
  content: '';
  background: url(./assets/images/verify_icon.png) no-repeat 0 0;
  background-size: 100%;
  width: 17px;
  height: 17px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-actions div {
  /* width: 25px;
  height: 25px; */
}

/* popup min style start */

.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1000;
  top: 0;
  left: 0;
}

.popup-min {
  border-radius: 7px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  color: #000;
  box-sizing: border-box;
  /* max-height: calc(100vh - 200px); */
  width: 100%;
  /* min-height: 150px; */
  transition: all ease 0.3s;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.29);
}

.popup-min h2 {
  font-size: 22px;
  font-weight: bolder;
  position: relative;
  /* margin-bottom: 10px; */
}

.tags-wrapper {
  max-height: calc(100vh - 350px);
  overflow: auto;
}

.popup-min-top {
  padding: 20px 20px 20px 20px;
  border-bottom: solid 1px #d9dae0;
}

.popup-min-bottom {
  padding: 0px 0 0;
  justify-content: space-between;
}

.popup-min-bottom-left {
  margin-right: 15px;
}

.popup-min-bottom-right {
  gap: 15px;
}

.popup-min-bottom .blue-button {
  background: #2b6fdb;
  border: solid 1px #2b6fdb;
  padding: 8px 12px 8px 28px;
  color: var(--primary-white);
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  font-size: 12px;
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  gap: 6px;
}

/* .popup-min-bottom .blue-button.close::after {
  background: url(./assets/images/cancel.png) no-repeat 0 0;
  background-size: 100%;
}

.popup-min-bottom .blue-button.save::after {
  background: url(./assets/images/save_white.png) no-repeat 0 0;
  background-size: 100%;
}

.popup-min-bottom .blue-button.resend::after {
  background: url(./assets/images/resend.png) no-repeat 0 0;
  background-size: 100%;
} */

.popup-min-bottom .blue-button:after {
  position: absolute;
  content: '';
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

/* popup min style end */

.form-label {
  /* font-weight: bold; */
  font-size: 12px;
  padding-bottom: 8px;
  display: inline-block;
}

table tr:hover {
  background-color: #eaf2ff;
}

button.add-new {
  width: 16px;
  height: 16px;
  background: url(./assets/images/plus_icon_grey.png) no-repeat 0 0;
  background-size: 100%;
  outline: none;
  border: none;
  cursor: pointer;
}

.tags-flex {
  align-items: center;
}

.tags-col {
  max-width: 95px;
  width: 100%;
  justify-content: center;
  /* flex-wrap: wrap; */
}

.tags-col span {
  width: 13px;
  height: 13px;
  margin: 0 4px 0 0;
  border-radius: 50%;
  text-align: center;
  display: block;
  position: relative;
}

.tags-col span .tag-name-tooltip,
.connection-tooltip {
  background: #d6d6d6;
  padding: 2px 4px 2px 4px;
  border-radius: 1px;
  font-size: 10px;
  display: none;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content !important;
  height: auto !important;
  word-break: break-word;
  z-index: 9;
  border: solid 1px #d6d6d6;
  color: #424242;
  font-weight: 500;
  text-align: left;
}
.tags-col span .tag-name-tooltip::after,
.connection-tooltip::after {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #d6d6d6;
  position: absolute;
  content: '';
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.tags-col span:hover .tag-name-tooltip,
.connection-status-round:hover .connection-tooltip {
  display: block;
}
.connection-status-round {
  position: relative;
}
.col-word-break {
  word-break: break-word;
}

.middle-container-top h1 span {
  font-size: 18px;
  font-weight: 500;
  color: var(--tab-color);
}

.middle-container-span {
  display: block;
  color: #161824 !important;
}

.gap20 {
  display: flex;
  gap: 20px;
}

.justify-center {
  justify-content: center;
}

.dashboard-table-headings .blue-button.create-new,
.dashboard-table-headings .blue-button {
  background: #33455f;
  border: solid 1px #33455f;
  padding: 8px 8px 8px 8px;
  color: var(--primary-white);
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  outline: none;
  font-size: 14px;
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
}

.dashboard-table-headings .blue-button.create-new em {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  border: solid 1px #fff;
  padding: 0px 4px;
}

.dashboard-tables-wrapper table tr td.create-col {
  font-weight: bold !important;
  font-size: 13px !important;
  text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #33455f;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #33455f;
}

.switch input:checked + .slider:before {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #33455f;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #33455f;
}

.switch input:checked + .slider:before {
  /* -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px); */
  left: 26px;
}

.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

.archive-disable {
  pointer-events: none;
  opacity: 0.3;
}

.date-picker-wrapper .react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  background: #fff;
  border: 1px solid #bababa;
  border-radius: 6px;
  font-family: Roboto;
  width: 100%;
  height: 30px;
}

.date-picker-wrapper .react-datetime-picker__inputGroup {
  padding: 0px 10px;
}

.date-picker-wrapper .react-datetime-picker {
  width: 100%;
}

.page-not-found-wrapper h1,
.unauthorised-page-wrapper h1 {
  font-size: 60px;
  font-weight: bold;
}

.page-not-found-wrapper h2,
.unauthorised-page-wrapper h2 {
  font-weight: 600;
}

.page-not-found-wrapper,
.unauthorised-page-wrapper {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--right-grey);
}

.unauthorised-page-wrapper img {
  width: 50px;
}

.dashboard-tables-wrapper table td.no-data {
  font-weight: bold !important;
  font-size: 12px;
  text-align: center;
  color: #101010;
  padding: 3px;
}

.no-level1-level2 {
  text-align: center;
  font-weight: bold;
  font-size: 13px;
}

.user-image .user-no-profpic {
  width: 40px;
  height: 40px;
  background-color: #7b8d8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 50%;
}

.multiselect-wrapper {
  position: relative;
}

.multiselect-selected {
  width: 100%;
  min-height: 34px;
  height: auto;
  border: solid 1px #bcbcbc;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.multiselect-selected-items {
  gap: 6px;
  padding: 7px 8px;
  align-items: center;
}

.multiselect-selected-items {
  flex-grow: 1;
}

.multiselect-dropdown-arrow {
  width: 24px;
  height: 24px;
  border-left: solid 1px #cccccc;
  position: relative;
  cursor: pointer;
}

.multiselect-dropdown-arrow::after {
  position: absolute;
  content: '';
  background-size: 100%;
  background: url(./assets/images/down_grey_sm.png) no-repeat 0 0;
  width: 15px;
  height: 8px;
  top: 9px;
  left: 3px;
  /* transform: translateY(-50%); */
  transition: all ease 0.3s;
}

.multiselect-wrapper.open .multiselect-dropdown-arrow::after {
  transform: rotate(180deg);
}

.multiselect-dropdown-list {
  position: absolute;
  top: 34px;
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-top: none;
  width: 100%;
  padding: 8px 12px;
  border-radius: 0 0 3px 3px;
  display: none;
  z-index: 2;
}

.multiselect-wrapper.open .multiselect-dropdown-list {
  display: block;
}

.multiselect-dropdown-search {
  position: relative;
}

.multiselect-dropdown-search input[type='text'] {
  padding: 6px 5px 5px 28px !important;
  height: 35px;
  font-size: 12px;
}

.multiselect-dropdown-search::after {
  position: absolute;
  content: '';
  background: url(./assets/images/search_icon.png) no-repeat 0 0;
  background-size: 100%;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 6px;
}

.multiselect-taglist-item .form-group {
  padding-top: 0;
}

.multiselect-taglist {
  margin-top: 10px;
}

.multiselect-taglist-item:last-child {
  border-bottom: none;
}

.multiselect-taglist-item {
  padding: 7px 0;
  border-bottom: solid 1px #f8f8f8;
  /* cursor: pointer; */
}

.multiselect-taglist-item .form-group label.form-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: -6px;
  padding-left: 0px !important;
  font-size: 12px;
  color: #202020;
  cursor: pointer;
}

.multiselect-taglist-item .form-checkbox .checkmark {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  position: absolute;
  background-color: transparent;
  border: 1px solid #00000033;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  transition: all 0.4s ease-in-out;
}

.multiselect-more-items {
  font-size: 10px;
  color: #797979;
  position: relative;
  cursor: pointer;
}

.multiselect-more-items::after {
  position: absolute;
  content: '';
  background: #797979;
  height: 1px;
  top: calc(100% + 1px);
  width: 100%;
  left: 0;
}

.filter-tags .multiselect-taglist-item .form-checkbox .checkmark {
  width: 16px;
  height: 16px;
}

.filter-tags .multiselect-taglist-item .form-checkbox .checkmark::after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 10px;
}

.filter-tags .multiselect-taglist-item .form-group.form-checkbox-group {
  margin-bottom: 0;
}

.multiselect-wrapper.open .multiselect-selected {
  border-radius: 6px 6px 0 0;
}

.multiselect-add-new-tag {
  border: dashed 1px #33455f;
  padding: 10px;
  text-align: center;
  margin-top: 10px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.multiselect-add-new-tag span {
  position: relative;
}

.multiselect-add-new-tag span::before {
  position: absolute;
  content: '';
  background: url(./assets/images/close-icon-black.png) no-repeat 0 0;
  background-size: 100%;
  left: -18px;
  top: 50%;
  transform: translateY(-50%) rotate(46deg);
  width: 11px;
  height: 11px;
}

.multiselect-selected-item {
  background: #e7e7e7;
  padding: 2px 4px;
  border-radius: 1px;
  gap: 4px;
  font-size: 12px;
  color: #424242;
  align-items: center;
}

.multiselect-close {
  background: url(./assets/images/close-icon-grey.png) no-repeat center center;
  background-size: 100%;
  width: 12px;
  height: 12px;
  cursor: pointer;
  flex-shrink: 0;
}

.popup-min-top-header {
  justify-content: space-between;
}

.form-group .invalid-error input[type='text'],
.form-group .invalid-error textarea,
.form-group .invalid-error input[type='date'],
.form-group .invalid-error input[type='email'],
.form-group .invalid-error input[type='password'] {
  border: solid 1px red;
}

.form-group .schedule-release-single-form-item.time-picker-item.invalid-error {
  border: solid 1px red;
}

.form-group .multiselect-selected.invalid-error {
  border: solid 1px red;
}

.form-group
  .schedule-release-single-form-item.time-picker-item.invalid-error
  input[type='text'],
.form-group
  .schedule-release-single-form-item.time-picker-item.invalid-error
  textarea,
.form-group
  .schedule-release-single-form-item.time-picker-item.invalid-error
  input[type='date'],
.form-group
  .schedule-release-single-form-item.time-picker-item.invalid-error
  input[type='email'],
.form-group
  .schedule-release-single-form-item.time-picker-item.invalid-error
  input[type='password'] {
  border: none;
}

.form-group
  .schedule-release-single-form-item.time-picker-item.invalid-error
  input[type='text']:nth-child(2) {
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}
.popup-min-bottom-tmargin {
  margin-top: 20px;
}

.popup-min-top-header {
  justify-content: space-between;
  border-bottom: solid 1px #ebebed;
  margin-bottom: 15px;
  align-items: center;
  padding-bottom: 10px;
}

.popup-close {
  cursor: pointer;
}

.image-upload-box {
  background: #fff;
  border: solid 1px #bababa;
  padding: 0px 0px 0px 8px;
  width: 100%;
  border-radius: 6px;
  align-items: center;
  height: 34px;
  overflow: hidden;
}

.image-upload-box .image-name {
  flex-grow: 1;
  margin-right: 6px;
  display: flex;
  align-items: center;
  word-break: break-word;
  font-size: 13px;
  position: relative;
  padding-right: 20px;
  color: #000;
}

.blue-button.add-image {
  padding: 5px 10px 5px 32px;
  flex-shrink: 0;
  flex-grow: unset;
  margin-right: 0;
  border-radius: 0px;
  height: 31px;
  background: #f8f8f8;
  border: none;
  color: #535353;
  margin-right: 0 !important;
  position: relative;
  font-size: 12px;
  font-weight: normal;
  transition: all ease 0.3s;
  font-family: 'Roboto';
}

.blue-button.add-image:hover,
.blue-button.add-image.active {
  background: #33455f;
  color: #fff;
}

.blue-button.add-image:hover::after,
.blue-button.add-image.active::after {
  filter: brightness(0) saturate(100%) invert(89%) sepia(0%) saturate(26%)
    hue-rotate(140deg) brightness(108%) contrast(110%);
}

.blue-button.add-image::before {
  position: absolute;
  content: '';
  background: #bababa;
  height: 100%;
  width: 1px;
  left: 0;
  top: 0;
}

.add-recipient-text:not(:last-child) {
  padding-bottom: 12px;
}

.add-recipient-text-span {
  position: relative;
}

.delete-recipient {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.delete-recipient img {
  display: block;
}

.add-form-popup .form-group.add-recepient-btn-wrapper {
  padding: 20px 0 0;
  margin-bottom: 15px;
}

.calender-icon-img::before {
  position: absolute;
  content: '';
  background-color: #ccc;
  width: 1px;
  height: 100%;
  left: -7px;
  top: -1px;
}

.calender-icon-img {
  position: relative;
}

.dashboard-tables-wrapper.feedback-listing-table table tr:nth-child(even) {
  background: none;
}

.dashboard-tables-wrapper table tr.light-grey {
  background: #fcfbfb !important;
}

.dashboard-tables-wrapper table tr.light-grey:hover,
.dashboard-tables-wrapper.feedback-listing-table
  table
  tr:nth-child(even):hover {
  background-color: #eaf2ff !important;
}

.dashboard-tables-wrapper table tr.feedback-detail-row td {
  border-bottom: none;
}

.dashboard-tables-wrapper
  table
  tr.feedback-detail-row
  td
  .release-name-col-details {
  cursor: pointer;
}

.feedback-description {
  padding: 0 10px 0 35px;
}

.feedback-description-release {
  padding: 0 10px 0 5px;
}

.dashboard-tables-wrapper table tr.feedback-detail-row td {
  color: #101010;
}

.dashboard-tables-wrapper table tr.feedback-description-row td {
  color: #787878;
  font-size: 10px;
}

.react-multi-email .data-labels > div {
  background: #e7e7e7;
  padding: 2px 4px;
  border-radius: 1px;
  gap: 4px;
  display: flex;
}

.react-multi-email .data-labels {
  display: flex !important;
  gap: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap !important;
}

.form-checkbox .checkmark {
  cursor: pointer;
}

.dashboard-table-headings.tags-heading
  .form-group
  label.form-checkbox
  + span.check-text,
.multiselect-taglist label.form-checkbox + span.check-text {
  padding: 0;
  padding-left: 25px;
}

.tags-heading .form-group.form-checkbox-group {
  padding: 0;
  margin-bottom: 0;
}

.table-checkbox-group,
.dl-checkbox-group .checkbox-text-wrapper,
.table-checkbox-group .checkbox-text-wrapper .dl-name-td,
.table-checkbox-group .checkbox-text-wrapper .tag-name-td {
  flex-grow: 1;
}

.table-checkbox-group .checkbox-text-wrapper {
  display: flex;
  flex-grow: 1;
}

.table-checkbox-group .edit-text.edit-text-visible {
  flex-grow: 1;
}

.level .multiselect-selected-item {
  max-width: 80%;
  word-break: break-word;
}

.level .multiselect-dropdown-arrow {
  flex-shrink: 0;
}

.level .multiselect-selected-item.single-item {
  max-width: 95%;
}
.level-headings {
  font-size: 14px;
  word-break: break-word;
}
.level-name-seperator {
  padding: 0 3px;
}
.middle-container-level-top-headings {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.middle-container-top.level-headings-main {
  flex-direction: column;
  gap: 10px;
}
.submit-feedback-icon {
  background: url(./assets/images/submit_feedback.png) no-repeat 0 0;
  background-size: 100%;
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-left: 6px;
}
.submit-feedback-icon-div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.application-feedback-popup
  .textarea-form-wrapper
  textarea.application-feedback-popup-text-area {
  height: 120px;
  appearance: none;
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-family: 'Roboto';
  background: #fff;
  color: #101010;
  font-weight: normal;
  font-size: 12px;
}
.application-feedback-popup .textarea-form-wrapper p {
  margin-bottom: 8px;
}
.count-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.count-label .error-message {
  padding: unset;
}

.react-datetime-picker__calendar {
  z-index: 9 !important;
}

.reactour__popover {
  left: 20px !important;
}

.reactour__popover span {
  background-color: #33455f !important;
}

.tour-navigation {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.tour-navigation .tour-content {
  font-size: 18px; /* Adjust the value as needed */
  line-height: 1.6; /* Optional: Increase line height for better readability */
  margin-right: 20px; /* Add space for the buttons */
}

.tour-prev-button {
  background-color: #33455f; /* Blue color */
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tour-next-button {
  background-color: #33455f; /* Blue color */
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Optional: Add margin for spacing */
.tour-prev-button {
  margin-left: 10px;
  margin-top: 10px;
}

.tour-next-button {
  margin-right: 10px;
  margin-top: 10px;
}

@media screen and (max-width: 1400px) {
  .dashboard-tables-wrapper table.feedback-pg-table-wrapper td.read-status-col {
    padding: 0 0 0 4px;
  }

  .dashboard-tables-wrapper table.feedback-pg-table-wrapper .smiley-wrapper {
    gap: 2px;
    padding: 2px;
  }

  .dashboard-tables-wrapper
    table.feedback-pg-table-wrapper
    .smiley-wrapper
    img {
    width: 14px;
  }

  .dashboard-tables-wrapper
    table.feedback-pg-table-wrapper
    .feedback-email
    img {
    width: 16px;
  }

  .dashboard-tables-wrapper table.feedback-pg-table-wrapper .feedback-email {
    gap: 3px;
  }

  .dashboard-tables-wrapper
    table.feedback-pg-table-wrapper
    .feedback-actions-flex {
    gap: 1px;
    align-items: center;
    padding-right: 16px;
  }

  .right-main-container {
    margin-left: 19.29vw;
  }

  .innerContainer.Scheduled-Release {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1370px) {
  .right-container {
    max-width: 22.29vw;
  }

  .dashboard-table-headings-left .dashboard-table-heading-tabs {
    gap: 6px;
  }

  .dashboard-table-headings-left .dashboard-table-heading-tabs span {
    padding: 6px 6px;
  }
}
