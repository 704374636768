.dashboard-tables-wrapper {
  border: 1px solid var(--tab-border);
  border-radius: 8px;
  padding: 1px;
  /* max-height: 360px; */
  /* overflow-y: auto; */
}

/* margin-top: 20px; */
.dashboard-table-headings {
  margin-bottom: 15px;
}

.dashboard-tables-wrapper table,
.scheduled-release-table table {
  width: 100%;
  border-collapse: collapse;
}

.dashboard-tables-wrapper table {
  border-collapse: separate;
  border-spacing: 0;
}

.dashboard-tables-wrapper table th,
.scheduled-release-table table th {
  background: #f3f3f3;
  color: #101010;
  /* font-size: 12px;
  font-weight: 500;
  color: var(--tab-color); */
  font-size: 14px;
  font-weight: normal;
}

.scheduled-release-table table th {
  text-align: left;
}

.dashboard-tables-wrapper table td {
  text-align: left;
}

.scheduled-release-table {
  width: 100%;
  /* max-height: 320px;
  overflow-y: auto; */
}

.dashboard-tables-wrapper table tr th {
  padding: 10px 8px;
  position: sticky;
  top: -2px;
  z-index: 4;
  height: 45px;
  text-align: left;
}

.dashboard-tables-wrapper table tr td,
.dashboard-tables-wrapper table tr th {
  border-bottom: 1px solid var(--tab-border);
}

/* .scheduled-release-table table tr td,
.scheduled-release-table table tr th {
  border-bottom: 1px solid var(--tab-border);
} */

.dashboard-tables-wrapper table tr td {
  padding: 7px 8px;
}

.scheduled-release-table table tr td,
.scheduled-release-table table tr th {
  padding: 4px 0px 6px 0px;
}

.scheduled-release-table table tr:hover {
  background-color: transparent;
}

tr.scheduler-release-main-row:not(:last-child) td {
  border-bottom: solid 1px #bababa;
}

tr.scheduler-release-main-row .scheduler-release-header td {
  border-bottom: none;
}
.scheduled-release-table .schedule-release-name {
  cursor: pointer;
}
.dashboard-tables-wrapper table tr:last-child td,
.scheduled-release-table table tr:last-child td {
  border-bottom: none;
}

.dashboard-tables-wrapper table tr td:first-child,
.scheduled-release-table table tr td:first-child {
  /* font-weight: 600; */
}

.scheduled-release-table table tr td {
  font-size: 12px;
  vertical-align: middle;
}

.scheduled-release-table h3 {
  /* font-weight: bold; */
  font-size: 14px;
}

.scheduled-release-table table tr.scheduler-release-header td {
  padding: 4px 0px 0 0px;
}

.scheduler-release-actions {
  text-align: right;
}

.scheduler-release-actions .flex {
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
}

.dashboard-tables-wrapper table tr td {
  font-size: 12px;
  color: #535353;
}

.dashboard-tables-wrapper table tr td:not(:last-child),
.dashboard-tables-wrapper table tr th:not(:last-child) {
  border-right: dashed 1px #bababa;
  height: 45px;
  padding: 5px 8px;
}

.dashboard-tables-wrapper table tr td:not(:last-child) {
  position: relative;
}

.dashboard-tables-wrapper table tr:nth-child(even) {
  background: #fcfbfb;
}

.dashboard-tables-wrapper table tr:nth-child(even):hover {
  background-color: #eaf2ff;
}

.dashboard-tables-wrapper table tr th.no-rborder,
.dashboard-tables-wrapper table tr td.no-rborder {
  border-right: none;
}

.dashboard-tables-wrapper table tr.tr-click {
  background-color: #eaf2ff;
}

.dashboard-tables-wrapper table tr.release-row {
  cursor: pointer;
}

.dashboard-tables-wrapper table tr td:not(:last-child)::before {
  right: -2px;
  top: 0;
  width: 3px;
  height: 8px;
  background: #fff;
  position: absolute;
  content: '';
  z-index: 0;
}

.dashboard-tables-wrapper table tr th:not(:last-child)::after {
  right: -2px;
  bottom: -2px;
  width: 3px;
  height: 10px;
  background: #f3f3f3;
  position: absolute;
  content: '';
  z-index: 1;
}

.dashboard-tables-wrapper table tr td:not(:last-child)::after {
  right: -2px;
  bottom: 0;
  width: 3px;
  height: 10px;
  background: #fff;
  position: absolute;
  content: '';
  z-index: 0;
}

.dashboard-tables-wrapper table tr:hover td:not(:last-child)::before,
.dashboard-tables-wrapper table tr:hover td:not(:last-child)::after,
.dashboard-tables-wrapper table tr.row-active td::before,
.dashboard-tables-wrapper table tr.row-active td::after,
.dashboard-tables-wrapper table tr.tr-click td:not(:last-child)::after,
.dashboard-tables-wrapper table tr.tr-click td:not(:last-child)::before {
  background: #eaf2ff;
  z-index: 0;
}
.dashboard-tables-wrapper table tr.light-grey.row-active,
.dashboard-tables-wrapper table tr.row-active {
  background-color: #eaf2ff !important;
}
.dashboard-tables-wrapper table tr th:not(:last-child)::before {
  right: -2px;
  top: 0;
  width: 3px;
  height: 8px;
  background: #f3f3f3;
  position: absolute;
  content: '';
  z-index: 1;
}

.dashboard-tables-wrapper table tr td .status.published {
  background-color: var(--published);
}

.dashboard-tables-wrapper table tr td .status.sending {
  background-color: #90a7d7;
}

.dashboard-tables-wrapper table tr td .status.edited {
  background-color: #be8dca;
}

.dashboard-tables-wrapper table tr td .status.scheduled {
  background-color: var(--scheduled);
}

.dashboard-tables-wrapper table tr td .status.draft {
  background-color: var(--draft);
}

.dashboard-tables-wrapper table tr td .status {
  border-radius: 6px;
  padding: 5px 15px;
  font-weight: 500;
  display: block;
  color: #fff;
  text-align: center;
  border: solid 1px transparent;
}

.actions-col {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.actions-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  width: 160px;
  padding: 10px;
  border: solid 1px var(--tab-border);
  z-index: 2;
  background: var(--primary-white);
  font-size: 14px;
  color: var(--primary-black);
  list-style: none;
  border-radius: 8px;
  display: none;
}

.actions-dropdown.show {
  display: block;
  z-index: 10;
}

.actions-dropdown li {
  padding: 6px 10px;
  margin-bottom: 5px;
  border-radius: 8px;
  cursor: pointer;
}

.actions-dropdown li.delete {
  margin-top: 15px;
  border-top: solid 1px #eceded;
  color: var(--delete-red);
}

.actions-dropdown li.active,
.actions-dropdown li:hover {
  background: var(--draft);
}

.action-td {
  width: 40px;
}

.dashboard-tables-wrapper .tags-col {
  max-width: 62px;
  justify-content: flex-start;
}

.dashboard-tables-wrapper .tags-col span {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.time-span {
  display: block;
}

.dashboard-time-col {
  width: 160px;
}

table tr td.text-right {
  text-align: right;
}

.pagination-table table tr.pagination-row td {
  padding: 10px 8px;
  position: sticky;
  bottom: -2px;
  z-index: 4;
  height: 40px;
  background-color: #f3f3f3;
}

.pagination-table table tr.pagination-row .table-data-pagination {
  margin-top: 0;
}

.pagination-table table tr.pagination-row .table-data-pagination .first,
.pagination-table table tr.pagination-row .table-data-pagination .last {
  display: none;
}

.pagination-table
  table
  tr.pagination-row
  .table-data-pagination
  .page-nums
  li:first-child,
.pagination-table table tr.pagination-row .table-data-pagination span.next {
  background: #cdcdcd;
  font-size: 0;
  position: relative;
  width: 23px;
  height: 23px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}

.pagination-table
  table
  tr.pagination-row
  .table-data-pagination
  .page-nums
  li:first-child::after,
.pagination-table
  table
  tr.pagination-row
  .table-data-pagination
  span.next::after {
  position: absolute;
  content: '';
  background: url(../../assets/images/right_grey_sm.png) no-repeat 0 0;
  width: 9px;
  height: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pagination-table
  table
  tr.pagination-row
  .table-data-pagination
  .page-nums
  li:first-child::after {
  transform: translate(-50%, -50%) rotate(180deg);
}

.pagination-table
  table
  tr.pagination-row
  .table-data-pagination
  ul.page-nums
  li {
  padding: 2px;
  width: 23px;
  height: 23px;
  font-size: 12px;
  font-weight: 600;
  color: #949292;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: solid 1px transparent;
}

.pagination-table
  table
  tr.pagination-row
  .table-data-pagination
  ul.page-nums
  li {
  margin-right: 7px;
}

.pagination-table
  table
  tr.pagination-row
  .table-data-pagination
  ul.page-nums
  li.active {
  border-color: #2b6fdc;
  background: #eaf2ff;
  color: #101010;
}

.pagination-table table tr.pagination-row .table-data-pagination ul.page-nums {
  padding: 0 0px 0 10px;
}

.checkbox-text-wrapper {
  padding-left: 26px;
}

.dashboard-tag-col {
  width: 85px;
}

table tr td.no-padding {
  padding: 0;
}

.delete-row {
  flex-shrink: 0;
  margin-right: 3px;
}

.th-down {
  background: url(../../assets/images/down_arrow_grey.png) no-repeat 0 0;
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.th-down.active {
  filter: brightness(0) saturate(100%) invert(23%) sepia(50%) saturate(440%)
    hue-rotate(175deg) brightness(89%) contrast(87%);
}

.th-down.sorted {
  transform: rotate(180deg);
}

.status-list {
  cursor: pointer;
}

.scheduler-release-actions .delete-icon {
  position: relative;
  z-index: 1;
}

.scheduler-release-main-row .scheduler-release-main-row-td {
  padding-right: 10px;
  padding-left: 5px;
}
.step-heading {
  font-weight: 600;
  font-size: 14px;
}
.popup-min-top p span {
  font-weight: 500;
  font-size: 13px;
}
.popup-min-top.couting p {
  margin-bottom: 5px;
}
