/* tag popup css starts */
.popup-min.tags-popup {
  max-width: 335px;
  padding: 10px 5px 10px 10px;
}
.tags-popup .tags-wrapper {
  list-style: none;
  margin-top: 15px;
}

.tags-popup .tag-item {
  margin-bottom: 10px;
  align-items: center;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: solid 1px #F8F8F8;
  padding-right: 12px;
}

.tags-popup .tag-item:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.tags-popup .tag-item .tag-color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
}

.tags-popup .tag-name {
  padding: 0 10px 0 8px;
  font-size: 12px;
  flex-grow: 1;
}
.no-tags-found{
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}



.tags-popup.public-library-tags-popup .popup-min-bottom-tmargin{
  margin-top: 10px;
}


/* tag popup css ends  */
