.success-popup {
    max-width: 520px;
    height: 200px;
  }
  .success-popup .popup-min-top {
    border-bottom: none;
    text-align: center;
    padding-top: 12px;
  }
  .success-popup .popup-min-bottom .blue-button.save::after {
    /* background: url(../../assets/images/checked_icon_white.png) no-repeat 0 0; */
    background-size: 100%;
  }
  .success-close{
    padding: 40px 10px 0;
  }
  