.parentDisable{
    position: fixed;
    top: 0;
    left: 0;
    background: #666;
    opacity: 0.8;
    z-index: 4001;
    height: 100%;
    width: 100%;
  }
  
  .overlay-box {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: white; background: #666666; opacity: .8;
    z-index: 1000;
  }